export const images = {
  "01": {
    video: require("./Tripod/Tripod.mp4"),
    image: require("./Tripod/TripodMedium.png"),
    small: require("./Tripod/TripodSmall.png"),
    icon: require("../gripIcons/TripodIcon.svg"),
  },
  "02": {
    video: require("./Power/Power.mp4"),
    image: require("./Power/PowerMedium.png"),
    small: require("./Power/PowerSmall.png"),
    icon: require("../gripIcons/PowerIcon.svg"),
  },
  "03": {
    video: require("./Trigger/Trigger.mp4"),
    image: require("./Trigger/TriggerMedium.png"),
    small: require("./Trigger/TriggerSmall.png"),
    icon: require("../gripIcons/TriggerIcon.svg"),
  },
  "04": {
    video: require("./Precision_Open/Precision_Open.mp4"),
    image: require("./Precision_Open/Precision_OpenMedium.png"),
    small: require("./Precision_Open/Precision_OpenSmall.png"),
    icon: require("../gripIcons/PrecisionOpenIcon.svg"),
  },
  "05": {
    video: require("./Precision_Closed/Precision_Closed.mp4"),
    image: require("./Precision_Closed/Precision_ClosedMedium.png"),
    small: require("./Precision_Closed/Precision_ClosedSmall.png"),
    icon: require("../gripIcons/PrecisionClosedIcon.svg"),
  },
  "0E": {
    video: require("./Rock/Rock.mp4"),
    image: require("./Rock/RockMedium.png"),
    small: require("./Rock/RockSmall.png"),
    icon: require("../gripIcons/RockIcon.svg"),
  },
  "06": {
    video: require("./Key/Key.mp4"),
    image: require("./Key/KeyMedium.png"),
    small: require("./Key/KeySmall.png"),
    icon: require("../gripIcons/KeyIcon.svg"),
  },
  "07": {
    video: require("./Point/Point.mp4"),
    image: require("./Point/PointMedium.png"),
    small: require("./Point/PointSmall.png"),
    icon: require("../gripIcons/FingerPointIcon.svg"),
  },
  "08": {
    video: require("./Mouse/Mouse.mp4"),
    image: require("./Mouse/MouseMedium.png"),
    small: require("./Mouse/MouseSmall.png"),
    icon: require("../gripIcons/MouseIcon.svg"),
  },
  "09": {
    video: require("./Column/Column.mp4"),
    image: require("./Column/ColumnMedium.png"),
    small: require("./Column/ColumnSmall.png"),
    icon: require("../gripIcons/ColumnIcon.svg"),
  },
  "0A": {
    video: require("./Relaxed/Relaxed.mp4"),
    image: require("./Relaxed/RelaxedMedium.png"),
    small: require("./Relaxed/RelaxedSmall.png"),
    icon: require("../gripIcons/RelaxedIcon.svg"),
  },
  "0C": {
    video: require("./Tap/Tap.mp4"),
    image: require("./Tap/TapMedium.png"),
    small: require("./Tap/TapSmall.png"),
    icon: require("../gripIcons/TapIcon.svg"),
  },
  "0D": {
    video: require("./Phone/Phone.mp4"),
    image: require("./Phone/PhoneMedium.png"),
    small: require("./Phone/PhoneSmall.png"),
    icon: require("../gripIcons/PhoneIcon.svg"),
  },
  "0B": {
    video: require("./Glove/Glove.mp4"),
    image: require("./Glove/GloveMedium.png"),
    small: require("./Glove/GloveSmall.png"),
    icon: require("../gripIcons/GloveIcon.svg"),
  },
}
