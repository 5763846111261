interface SortArg {
  [arg: string]: string | number | { seconds: number; nanoseconds: number }
}

export const tableSorter = (sorting: { title: string; asc: boolean }, a: SortArg, b: SortArg) => {
  let stringA: string | number | { seconds: number; nanoseconds: number }
  let stringB: string | number | { seconds: number; nanoseconds: number }
  const entryA = a[sorting.title]
  const entryB = b[sorting.title]

  const isTimestamp = (entry: unknown): entry is { seconds: number; nanoseconds: number } =>
    typeof entry === "object" && entry !== null && "seconds" in entry

  if (isTimestamp(entryA) && isTimestamp(entryB)) {
    stringA = entryA.seconds * 1000
    stringB = entryB.seconds * 1000
  } else if (
    sorting.title === "date" ||
    (typeof entryA === "number" && typeof entryB === "number")
  ) {
    stringA = entryA
    stringB = entryB
  } else if (sorting.title === "Time") {
    stringA = parseInt(entryA as string)
    stringB = parseInt(entryB as string)
  } else {
    stringA = entryA.toString().toLowerCase()
    stringB = entryB.toString().toLowerCase()
  }

  if (stringA < stringB) {
    if (sorting.asc) {
      return -1
    } else {
      return 1
    }
  } else if (stringA > stringB) {
    if (sorting.asc) {
      return 1
    } else {
      return -1
    }
  } else {
    return 0
  }
}
