import ViewTranslation from "@components/modals/translation/ViewTranslation"
import { useAuth } from "@context/AuthContext"
import {
  langToShort,
  languagesLong,
  PossibleLangLong,
  PossibleLangShort,
} from "@covvi/common-functions"
import { languageSelector as ls } from "@covvi/language-selector"
import { DisplayTranslation, NewTableOptions, StoredTranslation } from "@typesFolder/types"
import Table from "@ui/table/Table"
import { truncateString } from "@util/commonFunctions/truncateString"
import {
  getTranslations,
  requestContext,
  updateTranslation,
} from "@util/firebase/translationFunctions"
import { useEffect, useState } from "react"

const Translations = () => {
  const { profile } = useAuth()
  const [translations, setTranslations] = useState<StoredTranslation[]>([])
  const [displayTranslations, setDisplayTranslations] = useState<DisplayTranslation[]>()
  const [showingCompleted, setShowingCompleted] = useState(true)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [currentTranslation, setCurrentTranslation] = useState<string | undefined>()
  const [languages, setLanguages] = useState<
    undefined | { long: PossibleLangLong[]; short: PossibleLangShort[] }
  >()

  const setTableOptions = (): NewTableOptions => {
    return {
      fieldIds: ["en", ...languages!.short],
      fieldTitles: ["English", ...languages!.long],
      pillArray: {
        buttons: [
          { title: "Show Completed", onClick: () => setShowingCompleted(true) },
          { title: "Hide Completed", onClick: () => setShowingCompleted(false) },
        ],
        selected: showingCompleted ? "Show Completed" : "Hide Completed",
        setSelected: () => {},
      },
      onClick: (currentTrans: StoredTranslation) => setCurrentTranslation(currentTrans.key),
      searchParams: ["en", ...languages!.short],
    }
  }

  const editTranslations = (res: StoredTranslation[]) =>
    res.map(
      (translation) => ({
        ...translation,
        en: truncateString(translation.en?.translation || "", 50),
        zh: truncateString(translation.zh?.translation || "", 8),
        ja: truncateString(translation.ja?.translation || "", 8),
        es: truncateString(translation.es?.translation || "", 8),
        tr: truncateString(translation.tr?.translation || "", 8),
        ar: truncateString(translation.ar?.translation || "", 8),
        de: truncateString(translation.de?.translation || "", 8),
        uk: truncateString(translation.uk?.translation || "", 8),
      }),
      []
    )

  const handleUpdate = (key: string, language: PossibleLangShort, translation: string) => {
    return new Promise<void>((resolve, reject) => {
      updateTranslation(profile!, language, key, translation)
        .then(() => {
          setTranslations((current) =>
            current.map((entry) =>
              entry.key === key
                ? { ...entry, [language]: { setBy: profile!.uid, setOn: Date.now(), translation } }
                : entry
            )
          )
          resolve()
        })
        .catch(() => reject(false))
    })
  }

  const getTranslationContext = async (translation: StoredTranslation) => {
    return new Promise<void>((resolve, reject) => {
      requestContext(translation, profile!.email_address)
        .then(() => resolve())
        .catch(() => reject())
    })
    // send profile.email, language and translation key to software@covvi.com, we'll send a screenshot or some context to them
  }

  useEffect(() => {
    getTranslations().then((res) => setTranslations(res))
    let availableLanguages: PossibleLangLong[] =
      profile?.role === "Admin" ? languagesLong.slice(1) : profile?.languages || []
    setLanguages({
      long: availableLanguages,
      short: availableLanguages.length
        ? availableLanguages?.map((language) => langToShort(language) as PossibleLangShort)
        : [],
    })
  }, [])

  useEffect(() => {
    if (!translations) {
      return
    } else if (showingCompleted) {
      setDisplayTranslations(editTranslations(translations))
    } else {
      const filteredTrans = translations.filter((entry) => {
        let allLangs = true
        languages!.short.forEach((lang) => !entry[lang]?.translation && (allLangs = false))
        if (!allLangs) {
          return entry
        }
      })
      setDisplayTranslations(editTranslations(filteredTrans))
    }
    setLoaded(true)
    return () => setLoaded(false)
  }, [translations, showingCompleted])

  return !profile?.languages?.length && profile?.role !== "Admin" ? (
    <div>{ls.getText("translation_permission")}</div>
  ) : (
    <>
      {displayTranslations && loaded && (
        <Table data={displayTranslations} tableOptions={setTableOptions()} />
      )}
      {languages && currentTranslation && (
        <ViewTranslation
          showingModal={!!currentTranslation}
          setShowingModal={setCurrentTranslation}
          translation={translations.filter((entry) => entry.key === currentTranslation)[0]}
          updateTranslation={handleUpdate}
          languages={languages}
          getContext={getTranslationContext}
        />
      )}
    </>
  )
}
export default Translations
