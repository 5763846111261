import { useContext } from "react"

import { RemoteAssistContext } from "./RemoteAssistContext"

const useRemoteContext = () => {
  const context = useContext(RemoteAssistContext)
  if (context === undefined) {
    throw new Error("Remote Assist must be used within a RemoteAssistProvider")
  }
  return context
}

const useCurrentSessionContext = () => {
  const context = useRemoteContext()
  return {
    roomId: context.roomId,
    questionnaire: context.questionnaire,
    client: context.client,
    currentView: context.currentView,
    setIsInCustomBLECommand: context.setIsInCustomBLECommand,
    setIsInConfigActions: context.setIsInConfigActions,
    defaultConfigErrorRoomIDArray: context.defaultConfigErrorRoomIDArray,
    sendDefaultConfigErrorEmail: context.sendDefaultConfigErrorEmail,
  }
}

const useCurrentHandContext = () => {
  const context = useRemoteContext()
  return {
    initialConfig: context.initialConfig,
    currentConfig: context.currentConfig,
    configError: context.configError,
    setConfigError: context.setConfigError,
    inputA: context.elecA,
    inputB: context.elecB,
    electrodeUpdate: context.electrodeUpdate,
    handStatus: context.handStatus,
    digitPositions: context.digitPositions,
    errorLogs: context.errorLogs,
    commandResponse: context.commandResponse,
    clearCurrentCommandResponse: context.clearCurrentCommandResponse,
    emitData: context.emitData,
  }
}

const useServerStateContext = () => {
  const context = useRemoteContext()
  return {
    liveRooms: context.liveRooms,
    archivedRooms: context.archivedRooms,
    contextError: context.contextError,
  }
}

const useRoomFunctionsContext = () => {
  const context = useRemoteContext()
  return {
    joinRoom: context.joinRoom,
    closeRoom: context.closeRoom,
    getArchived: context.getArchived,
    getArchivedRoom: context.getArchivedRoom,
  }
}

export {
  useCurrentSessionContext,
  useCurrentHandContext,
  useServerStateContext,
  useRoomFunctionsContext,
}
