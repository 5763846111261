import { useAuth } from "@context/AuthContext"
import { Config, parseSerialNumber } from "@covvi/common-functions"
import { languageSelector as ls } from "@covvi/language-selector"

import CVKeyValueInfoCard from "@ui/cards/CVKeyValueInfoCard"
import { getProfile } from "@util/firebase/authFunctions"
import { useEffect, useState } from "react"

const ConfigSystemInfoTab = ({ config }: { config: Config }) => {
  const { profile } = useAuth()
  const [setBy, setSetBy] = useState<string>(ls.getText("Unknown"))

  useEffect(() => {
    if (config && profile?.role === "Customer Service Team Member") {
      getProfile(config.setBy).then(() => setSetBy(`${profile.first_name} ${profile.last_name}`))
    } else {
      config && setSetBy(config.setBy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-[30px]">
      <CVKeyValueInfoCard
        title={ls.getText("config_version_name")}
        value={config.configVersion?.toString()}
      />
      <CVKeyValueInfoCard title={ls.getText("Firmware Version")} value={config.firmwareVersion} />
      <CVKeyValueInfoCard title={ls.getText("Gateware Version")} value={config.gatewareVersion} />
      <CVKeyValueInfoCard title={ls.getText("Saved From Device")} value={config.userAppVersion} />
      <CVKeyValueInfoCard title={ls.getText("Set By")} value={setBy} />
      <CVKeyValueInfoCard
        title={ls.getText("Serial Number")}
        value={parseSerialNumber(config.configHex)}
      />
      {config.configImportedFrom && (
        <CVKeyValueInfoCard
          title={ls.getText("Config Imported From")}
          value={config.configImportedFrom}
        />
      )}
    </div>
  )
}

export default ConfigSystemInfoTab
