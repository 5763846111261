import React, { useEffect, useState } from "react"

import { getVal, schemaFromConfigHex, GeneralParam, UserGrips } from "@covvi/common-functions"
import { useAuth } from "@context/AuthContext"
import { sectionSetter } from "@util/configFunctions/sectionSetter"
import { ConfigSectionInterface } from "@typesFolder/types"

const HandControl = ({
  configHex,
  updateParameter,
  canEdit,
  userGrips,
  isLoading,
}: ConfigSectionInterface) => {
  const { profile } = useAuth()
  const [colOne, setColOne] = useState<JSX.Element[]>([])
  const [colTwo, setColTwo] = useState<JSX.Element[]>([])

  const triggerModeCompatibility: { [key: string]: number[] } = {
    long_hold_open_time: [0, 1, 2],
    fast_open_slow_close_time: [0],
    direction_change_time: [1],
    open_open_time: [2],
    cocontraction_time: [2],
  }

  useEffect(() => {
    const disabledOverrides: string[] = []
    if (profile?.role !== "Admin") {
      getVal("config_version", configHex).int < 106 &&
        getVal("auto_grip", configHex).hex === "00" &&
        disabledOverrides.push("auto_grip")
      Object.entries(triggerModeCompatibility).map(
        ([k, v]) => !v.includes(getVal("mode", configHex).int) && disabledOverrides.push(k)
      )
    }

    const sections = (wholeNames: string[]) =>
      sectionSetter(
        configHex,
        schemaFromConfigHex(configHex).hand_control,
        { wholeNames },
        { canEdit: isLoading ? false : canEdit, overrides: disabledOverrides, partials: [] },
        updateParameter
      )

    setColOne(
      sections([
        "interface_type",
        "input_layout_title",
        "mode",
        "long_hold_open_time",
        "fast_open_slow_close_time",
        "direction_change_time",
        "open_open_time",
        "cocontraction_time",
        "digit_hold_time",
      ])
    )
    setColTwo(
      sections([
        "beep",
        "vibration",
        "grip_force_mode",
        "grip_strength",
        "auto_relax",
        "auto_grip",
        "switch_to_primary",
        "input_a_speed",
        "input_b_speed",
        "thumb_mode",
        "thumb_strength",
      ])
    )
  }, [configHex, isLoading])

  return (
    <>
      <div className="lg:hidden grid gap-x-10  md:grid-cols-1 ">{[colOne, colTwo]}</div>
      <div className="hidden lg:grid grid-cols-2 gap-x-10">
        <div>{colOne}</div>
        <div>{colTwo}</div>
      </div>
    </>
  )
}

export default React.memo(HandControl)
