import { useState, useEffect, SyntheticEvent } from "react"
import useWindowSize from "@util/hooks/useWindowSize"
import { Slider } from "@mui/material"

interface Props {
  onVal: number
  coconVal: number | false
  maxVal: number
  maxInput: number
  disabled: boolean
  onMove: (values: number[]) => void
  onRelease: () => void
}

const ThresholdBar = ({
  onVal,
  coconVal,
  maxVal,
  maxInput,
  disabled,
  onMove,
  onRelease,
}: Props) => {
  const windowSize = useWindowSize()
  const [marks, setMarks] = useState<{ value: number; label: string }[]>([])

  useEffect(() => {
    if (windowSize.width) {
      let increment: number = Math.floor(maxInput / (windowSize.width > 640 ? 10 : 5) / 10) * 10
      let marks: { value: number; label: string }[] = []
      for (let index = 0; index < maxInput; index += increment) {
        maxInput - index > increment / 2 && marks.push({ value: index, label: index.toString() })
      }
      setMarks([...marks, { value: maxInput, label: maxInput.toString() }])
    }
  }, [maxInput, windowSize])

  const changeHandle = (_: Event, values: number | number[]) => {
    if (typeof values === "number") return
    onMove(
      coconVal
        ? [values[0] > 400 ? values[0] : 400, values[1], values[2]]
        : [values[0] > 400 ? values[0] : 400, values[1]]
    )
  }
  const releaseHandle = (_: Event | SyntheticEvent<Element, Event>, values: number | number[]) => {
    if (typeof values === "number") return
    onRelease()
  }

  return (
    <div className="flex flex-1 py-6">
      <Slider
        disabled={disabled}
        value={coconVal ? [onVal, coconVal, maxVal] : [onVal, maxVal]}
        min={0}
        max={maxInput}
        marks={marks}
        onChange={changeHandle}
        onChangeCommitted={releaseHandle}
        disableSwap={true}
      />
    </div>
  )
}

export default ThresholdBar
