import SelectInput from "@ui/inputs/ConfigInputs/SelectInput"
import { getVal, schemaFromConfigHex, UserGrips } from "@covvi/common-functions"
import React, { useEffect, useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import { ConfigSectionInterface } from "@typesFolder/types"

type ParamArr = { value: string; displayValue: string }[]

const GripSwitchTriggers = ({
  configHex,
  updateParameter,
  canEdit,
  userGrips,
  isLoading,
}: ConfigSectionInterface) => {
  const [triggers, setTriggers] = useState<ParamArr>([])
  const [triggerOptions, setTriggerOptions] = useState<ParamArr>([])
  const [gripOptions, setGripOptions] = useState<ParamArr>([])
  const [mapToGrip, setMapToGrip] = useState<ParamArr>([])

  useEffect(() => {
    const getSectionVal = (section: "grip_switch_triggers" | "map_to_grip") =>
      schemaFromConfigHex(configHex)[section].map((option) => ({
        displayValue: option.param_name,
        value: getVal(option.param_name, configHex).hex,
      }))
    const getSection = (section: "trigger_actions" | "grip_patterns") =>
      schemaFromConfigHex(configHex)[section].map((option, i) => ({
        displayValue:
          userGrips && option.name.startsWith("user_grip")
            ? ls.getText("user_grips") + " - " + ls.getText(userGrips[i - 15])
            : option.name,
        value: option.value,
        disabled: isLoading || option.name === "No Grip" ? true : undefined,
      }))
    setTriggers(getSectionVal("grip_switch_triggers"))
    setMapToGrip(getSectionVal("map_to_grip"))
    setTriggerOptions(getSection("trigger_actions"))
    setGripOptions(getSection("grip_patterns"))
  }, [configHex, isLoading])

  const SelectSection = ({ map, options }: { map: ParamArr; options: ParamArr }) => (
    <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-x-10">
      {map.map((param, i) => (
        <SelectInput
          title={param.displayValue}
          currentValue={param.value}
          key={i}
          setValue={updateParameter}
          canEdit={canEdit}
          options={options}
        />
      ))}
    </div>
  )

  return (
    <div className="flex flex-col justify-between py-2">
      <SelectSection map={triggers} options={triggerOptions} />
      <div className="border-t border-[#EAEAEA] my-[30px] w-full" />
      <SelectSection map={mapToGrip} options={gripOptions} />
    </div>
  )
}

export default React.memo(GripSwitchTriggers)
