import { useEffect, useState, useCallback, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { getMyUsers } from "@util/firebase/userFunctions"
import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { getProfile, resendInvite } from "@util/firebase/authFunctions"
import { updateUser } from "@util/firebase/userFunctions"
import extractHandInfo from "@util/commonFunctions/extractHandInfo"
import { Alert } from "@mui/material"
import { DefaultObject, ButtonObj, NewErrorObj, Hand, NewTableOptions } from "@typesFolder/types"

import { PillButtonArray } from "@ui/sections/PillButtonArray"
import Table from "@ui/table/Table"
import CVCardBody from "@ui/sections/CVCardBody"
import UserCard from "@components/cards/UserCard"
import CVCardFooter from "@ui/sections/CVCardFooter"
import BasicModal from "@ui/modals/BasicModal"
import Spinner from "@ui/spinners/Spinner"
import { ButtonRow } from "@ui/sections/ButtonRow"
import DeleteUserConfirmationModal from "@components/modals/users/DeleteUserConfirmationModal"
import RestrictUserConfirmation from "@components/modals/hands/RestrictUserConfirmation"
import AddAssociatedHandModal from "@components/modals/users/AddAssociatedHandModal"
import { AccreditationModal } from "@components/modals/users/AccreditationModal"
import { addAssociatedHand } from "@util/firebase/handFunctions/handFunctions"
import useWindowSize from "@util/hooks/useWindowSize"
import { getMyHands } from "@util/firebase/handFunctions/configFunctions"
import { ProfileData } from "@covvi/common-functions"

const User = () => {
  const location = useLocation()
  const { width = 1024 } = useWindowSize()
  const navigate = useNavigate()
  const { profile } = useAuth()
  const isCovvi = [
    "Sales Team Member",
    "Customer Service Team Member",
    "Tech Team Member",
    "Admin",
  ].includes(profile!.role)
  const newUserDataRef = useRef<any>(null)
  const [tabName, setTabName] = useState<"User Details" | "Associated Users" | "Hands">(
    "User Details"
  )
  const [associatedUsers, setAssociatedUsers] = useState<ProfileData[]>([])
  const [associatedHands, setAssociatedHands] = useState<Hand[]>([])
  const [showingDeleteUserConfirmation, setShowingDeleteUserConfirmation] = useState<boolean>(false)
  const [showingRestrictUserConfirmation, setShowingRestrictUserConfirmation] =
    useState<boolean>(false)
  const [showingAccreditConfirmation, setShowingAccreditConfirmation] = useState<boolean>(false)
  const [resending, setResending] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [alert, setAlert] = useState<NewErrorObj>()
  const [loadingUserData, setLoadingUserData] = useState<boolean>(true)
  const [userData, setUserData] = useState<ProfileData>()
  const [showAddAssociatedHandModal, setShowAddAssociatedHandModal] = useState(false)
  const [loadingAddHand, setLoadingAddHand] = useState(false)
  const [pillArray, setPillArray] = useState<{ title: string; onClick: () => void }[]>([
    { title: "User Details", onClick: () => setTabName("User Details") },
    { title: "Hands", onClick: () => setTabName("Hands") },
  ])
  const [hasChanges, setHasChanges] = useState<boolean>(false)

  const confirmRestricted = useRef(false)
  const confirmAccredit = useRef(false)

  useEffect(() => {
    setPillArray((current) => {
      !current.filter((pill) => pill.title === "Associated Users")[0] &&
        current.splice(1, 0, {
          title: "Associated Users",
          onClick: () => setTabName("Associated Users"),
        })
      return userData?.role === "User"
        ? current.filter((pill) => pill.title !== "Associated Users")
        : current
    })
  }, [userData])

  useEffect(() => {
    if (location && !updating) {
      setLoadingUserData(true)
      let uid = location.pathname.split("/")[2]
      getProfile(uid).then((res) => {
        setUserData({ ...res, isRestricted: res.isRestricted ? true : false })
        setTabName("User Details")
        setLoadingUserData(false)
      })
    }
  }, [location, updating])

  const checkIsAuthed = useCallback(() => {
    profile && profile.role === "User" && navigate("/")
  }, [navigate, profile])

  useEffect(() => {
    checkIsAuthed()
  }, [checkIsAuthed])

  useEffect(() => {
    if (userData) {
      getMyUsers(userData.uid, userData.role).then((users) => {
        if (users) {
          users.forEach((user: ProfileData & { full_name?: string }) => {
            user.full_name = `${user.first_name} ${user.last_name}`
          })
          setAssociatedUsers(users)
        } else {
          setAssociatedUsers([])
        }
      })
      if (userData.associated_hands) {
        getMyHands(userData).then((hands) => {
          setAssociatedHands(hands.map((hand) => extractHandInfo(hand)))
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const openHand = (data: DefaultObject): void => {
    navigate(`/hands/${data.id}`)
  }

  const covviTableOptions: NewTableOptions = {
    fieldIds: [
      "id",
      "latestConfig",
      "latestStats",
      "latestErrors",
      "latestCustomerConnection",
      "latestCovviConnection",
      "latestInvalid_config",
    ],
    fieldTitles: [
      ls.getText("Serial Number"),
      ls.getText("latest_config_change"),
      ls.getText("latest_stats"),
      ls.getText("latest_errors"),
      ls.getText("latest_customer_connection"),
      ls.getText("latest_covvi_connection"),
      ls.getText("latest_invalid_config"),
    ],
    searchParams: [
      "id",
      "latestConfig",
      "latestStats",
      "latestErrors",
      "latestCustomerConnection",
      "latestInvalid_config",
      "latestCovviConnection",
    ],
    ...(["Admin"].includes(profile!.role) && {}),
    onClick: openHand,
    button:
      profile &&
      ["Admin", "Tech Team Member", "Sales Team Member", "Customer Service Team Member"].includes(
        profile.role
      )
        ? {
            title: ls.getText("Add Associated Hand"),
            onClick: () => setShowAddAssociatedHandModal(true),
          }
        : undefined,
    pillArray: {
      buttons: pillArray,
      selected: tabName,
      setSelected: () => setTabName,
    },
  }

  const tableOptions: NewTableOptions = {
    fieldIds: ["id", ...(width > 640 ? ["laterality", "size", "color"] : [])],
    fieldTitles: [
      "Serial Number",
      ...(width > 640 ? [ls.getText("Laterality"), ls.getText("Size"), ls.getText("Color")] : []),
    ],
    onClick: openHand,
    searchParams: ["id", ...(width > 640 ? ["size", "color"] : [])],
    pillArray: {
      buttons: pillArray,
      selected: tabName,
      setSelected: () => setTabName,
    },
  }

  const openUser = (data: ProfileData) => {
    navigate(`/users/${data.uid}`, {
      state: {
        data,
      },
    })
  }

  const handleResendInvite = () => {
    setResending(true)
    resendInvite(userData!.uid, userData!.email_address)
      .then(() => {
        setAlert({ text: ls.getText("Invite Sent"), severity: "success" })
        setResending(false)
      })
      .catch(() => {
        setAlert({ text: ls.getText("Error Resending Invite"), severity: "error" })
        setResending(false)
      })
  }

  const handleUpdateUser = async () => {
    let errorArray = ["Error Updating User"]
    if (newUserDataRef.current) {
      const newUserData: ProfileData = newUserDataRef.current.getData()
      if (
        userData?.role === "User" &&
        userData?.isRestricted !== newUserData.isRestricted &&
        !confirmRestricted.current
      ) {
        setShowingRestrictUserConfirmation(true)
        errorArray.push("restricted_status_error")
      } else if (
        newUserData?.role === "Clinician" &&
        userData?.covvi_cert_date !== newUserData.covvi_cert_date &&
        !confirmAccredit.current
      ) {
        setShowingAccreditConfirmation(true)
        errorArray.push("accredit_fail")
      } else {
        setUpdating(true)
        if (newUserData) {
          await updateUser({ ...userData!, ...newUserData })
            .then(() => {
              setAlert({
                text: `${newUserData.role} ${ls.getText("Details Updated")}`,
                severity: "success",
              })
              setUpdating(false)
            })
            .catch((e) => {
              setAlert({ text: "Errors", severity: "error", textArray: errorArray })
              setUpdating(false)
            })
        } else {
          setUpdating(false)
        }
        setShowingRestrictUserConfirmation(false)
        confirmRestricted.current = false
        setShowingAccreditConfirmation(false)
        confirmAccredit.current = false
      }
    }
  }

  const userTableOptions: NewTableOptions = {
    fieldIds: ["full_name", "email_address", "role"],
    fieldTitles: ["Name", "Email Address", "Role"],
    onClick: openUser,
    searchParams: ["first_name", "last_name", "email_address", "role"],
    pillArray: {
      buttons: pillArray,
      selected: tabName,
      setSelected: () => setTabName,
    },
  }

  let buttonArray: ButtonObj[] = [
    {
      title: ls.getText("Update Details"),
      onClick: () => handleUpdateUser(),
      variant: "contained",
      loading: updating,
      disabled: !hasChanges,
    },
  ]
  if (userData && !userData.completed) {
    buttonArray.push({
      title: ls.getText("Resend Invite"),
      variant: "outlined",
      color: "primary",
      onClick: () => handleResendInvite(),
      loading: resending,
      setLoading: setResending,
    })
  }

  if (userData && (profile?.role === "Admin" || profile?.role === "Customer Service Team Member")) {
    buttonArray.push({
      title: `${ls.getText("Delete")} ${ls.getText(userData.role)}`,
      onClick: () => setShowingDeleteUserConfirmation(true),
      color: "error",
      variant: "outlined",
    })
  }

  const addAssociatedHandToFirebase = async (serialNumber: string) => {
    setLoadingAddHand(true)
    addAssociatedHand(userData!.uid, serialNumber)
      .then(() => {
        setShowAddAssociatedHandModal(false)
        let updatedNewlyAssociatedHands = [extractHandInfo({ serialNumber }), ...associatedHands]
        setAssociatedHands(updatedNewlyAssociatedHands)
        setLoadingAddHand(false)
      })
      .catch((e) => setLoadingAddHand(false))
  }

  const checkShowButtonArray = () => {
    if (!profile) {
      return false
    } else if (["Admin", "Customer Service Team Member"].includes(profile.role)) {
      return true
    } else if (
      userData &&
      profile.associated_users &&
      profile.associated_users.includes(userData.uid)
    ) {
      return true
    }
  }

  return (
    <>
      {!loadingUserData ? (
        <>
          {tabName === "User Details" && (
            <>
              <div className="pt-2">
                <PillButtonArray
                  pillArray={pillArray}
                  selected={tabName}
                  setSelected={setTabName}
                  extraMarginBottom={true}
                />
              </div>
              <CVCardBody roundedTop={true}>
                <UserCard
                  ref={newUserDataRef}
                  userData={userData}
                  section="Edit User"
                  onSubmit={handleUpdateUser}
                  setHasChanges={setHasChanges}
                />
              </CVCardBody>
              <CVCardFooter>
                {checkShowButtonArray() && <ButtonRow buttonArray={[...buttonArray]} />}
              </CVCardFooter>
            </>
          )}
          {tabName === "Associated Users" && (
            <Table data={associatedUsers} tableOptions={userTableOptions} />
          )}
          {tabName === "Hands" && (
            <Table
              data={associatedHands}
              tableOptions={isCovvi ? covviTableOptions : tableOptions}
            />
          )}

          {showingDeleteUserConfirmation && (
            <DeleteUserConfirmationModal
              modalOpen={showingDeleteUserConfirmation}
              setModalOpen={setShowingDeleteUserConfirmation}
              user={userData!}
              setSuccess={setAlert}
              deleteTargetEmail={userData!.email_address}
            />
          )}
          {showingRestrictUserConfirmation && userData && (
            <RestrictUserConfirmation
              showingModal={showingRestrictUserConfirmation}
              setShowingModal={setShowingRestrictUserConfirmation}
              currentRestrictedStatus={userData.isRestricted || false}
              confirm={() => {
                confirmRestricted.current = true
                handleUpdateUser()
              }}
            />
          )}
          {showingAccreditConfirmation && (
            <AccreditationModal
              showingModal={showingAccreditConfirmation}
              setShowingModal={setShowingAccreditConfirmation}
              confirm={() => {
                confirmAccredit.current = true
                handleUpdateUser()
              }}
            />
          )}
          {showAddAssociatedHandModal && (
            <AddAssociatedHandModal
              open={showAddAssociatedHandModal}
              setOpen={setShowAddAssociatedHandModal}
              addAssociatedHand={addAssociatedHandToFirebase}
              loading={loadingAddHand}
            />
          )}
          {alert && (
            <BasicModal open={alert ? true : false} setClosed={() => setAlert(undefined)}>
              <Alert severity={alert.severity} onClose={() => setAlert(undefined)}>
                {alert.textArray
                  ? alert.textArray.map((error) => ls.getText(error))
                  : ls.getText(alert.text)}
              </Alert>
            </BasicModal>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default User
