import {
  getStart,
  getVal,
  schemaFromConfigHex,
  toHex,
  UpdateUserGrip,
  UserGrip,
  UserGrips,
} from "@covvi/common-functions"
import { languageSelector as ls } from "@covvi/language-selector"

type GripTable = "a" | "b" | "c" | "d" | "MapToGrip"

export const updateGrip = (
  configHex: string,
  userGrips: UserGrips | undefined,
  table: GripTable,
  position: number,
  newGripHex: string,
  availableUserGrips: UserGrip[],
  addUserGrip: (grip: UpdateUserGrip) => void,
  editUserGrips: (grip: UserGrip, updateCommands: string[]) => void
): string | undefined => {
  const startPosition = getStart(
    table === "MapToGrip" ? `map_to_grip_${position}` : "grip_count_" + table,
    configHex
  )

  const tablesGripVals = configHex
    .substring(startPosition * 2 + 2, startPosition * 2 + 14)
    .match(/.{1,2}/g) as string[]

  const newGrip = schemaFromConfigHex(configHex).grip_patterns.find(
    (grip) => grip.value === newGripHex
  )

  const buildCommand = (command: string) => {
    const noGripBeforePosition = tablesGripVals.findIndex((grip) => grip === "00")
    if (command === "00") {
      tablesGripVals.splice(position, 1)
      tablesGripVals.push("00")
    } else if (noGripBeforePosition !== -1 && noGripBeforePosition < position) {
      tablesGripVals[noGripBeforePosition] = command
    } else {
      tablesGripVals[position] = command
    }

    return [
      toHex(startPosition),
      tablesGripVals
        .filter((e) => e !== "00")
        .length.toString()
        .padStart(2, "0"),
      ...(tablesGripVals as string[]),
    ].join("")
  }

  if (newGripHex === "00") {
    if (table === "MapToGrip") {
      throw new Error("Can't write no grip to map to grip") //should be impossible to get here.
    } else if (table === "a" && tablesGripVals.filter((e) => e !== "00").length === 1) {
      throw new Error(ls.getText("table_a_not_empty_warning"))
    } else return buildCommand("00")
  } else if (newGrip?.section !== 4) {
    return table === "MapToGrip" ? `${toHex(startPosition)}${newGripHex}` : buildCommand(newGripHex)
  } else if (newGrip?.section === 4 && userGrips && getVal("version", configHex)) {
    const currentUserGrip = userGrips.find((grip) => grip === userGrips[position])
    const isAvailableUserGrip = availableUserGrips.find((grip) => grip.Name === userGrips[position])
    if (currentUserGrip && isAvailableUserGrip) {
      return table === "MapToGrip"
        ? `${toHex(startPosition)}${newGripHex}`
        : buildCommand(newGripHex)
    } else if (isAvailableUserGrip) {
      const hasFreeSlots = userGrips?.indexOf("")
      const currentUserGrips = ["a", "b", "c", "d"].map((table) => {
        const tableStart = getStart("grip_count_" + table, configHex)
        const allTablesGrips = configHex
          .substring(tableStart * 2 + 2, tableStart * 2 + 14)
          .match(/.{1,2}/g)
        return allTablesGrips?.map((grip) => parseInt(grip, 16) - 15).filter((grip) => grip >= 0)
      })
      const unusedUserGrip = [...Array(6)]
        .map((v, i) => i)
        .filter((index) => ![...new Set(currentUserGrips.flat())].sort().includes(index))[0]
      if (hasFreeSlots !== -1 || unusedUserGrip !== undefined) {
        const index = unusedUserGrip !== undefined ? unusedUserGrip : hasFreeSlots
        addUserGrip({
          ...isAvailableUserGrip,
          index,
          command:
            table === "MapToGrip"
              ? `${toHex(startPosition)}${toHex(index + 15)}`
              : buildCommand(toHex(index + 15)),
        })
      } else {
        editUserGrips(
          isAvailableUserGrip,
          table === "MapToGrip"
            ? [...Array(6)].map((v, i) => `${toHex(startPosition)}${toHex(i + 15)}`)
            : [...Array(6)].map((v, i) => buildCommand(toHex(i + 15)))
        )
      }
    } else throw new Error("Chosen User Grip unavailable")
  } else throw new Error("Unrecognised grip section")
}
