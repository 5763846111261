import { ProfileData, schemaFromConfigHex } from "@covvi/common-functions"
import {
  DataCounter,
  DataGrips,
  DataLimits,
  DataTriggers,
  DataUsage,
  ShortSerialStats,
  SingleHandStats,
  StatisticsWithConfigInfo,
  StatsPoss,
} from "@typesFolder/statsTypes"
import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore, storage } from "../firebase"
import { getBlob, listAll, ref, StorageReference, uploadBytes } from "firebase/storage"
import { updateHandField } from "./handFunctions"
import { configHex105, configHex106, configHex107 } from "@assets/configs/configHexes"

export const getStats = ({
  handId,
  role,
  uid,
  associatedUsers,
}: {
  handId: string
  role: string
  uid: string
  associatedUsers?: string[]
}) => {
  return new Promise<SingleHandStats>((resolve, reject) => {
    const convertThumb = (snap: { [key: string]: number }) => {
      let returnSnap: { [key: string]: number } = {}
      const oldNames = [
        "thumb_tap_pronate_non_opposed",
        "thumb_tap_pronate_opposed",
        "thumb_tap_supinate_non_opposed",
        "thumb_tap_supinate_opposed",
      ]

      const newNames = [
        "non_opposed_thumb_tap_in",
        "opposed_thumb_tap_in",
        "non_opposed_thumb_tap_out",
        "opposed_thumb_tap_out",
      ]

      Object.entries(snap).forEach(([key, value]) => {
        if (oldNames.indexOf(key) >= 0) {
          returnSnap[newNames[oldNames.indexOf(key)]] = value as number
        } else {
          returnSnap[key] = value as number
        }
      })

      return returnSnap
    }
    const getAll = ["Admin", "Tech Team Member", "Customer Service Team Member"].includes(role)
    const docRef = getAll
      ? collection(firestore, `Hands/${handId}/AppStatistics`)
      : query(
          collection(firestore, `Hands/${handId}/AppStatistics`),
          where("set_by", "in", associatedUsers ? [...associatedUsers.slice(0, 28), uid] : [uid])
        )
    getDocs(docRef)
      .then((querySnapshot) => {
        let handStats = new Map<string, StatisticsWithConfigInfo>()
        let snapShotStats = querySnapshot.docs
        const first = getAll ? false : snapShotStats.shift()
        snapShotStats.forEach((statSnapshot) => {
          let snapshotEdited = statSnapshot.data()
          snapshotEdited = {
            ...snapshotEdited,
            data_triggers: convertThumb(snapshotEdited["data_triggers"]),
          }

          if (snapshotEdited["data_triggers"].hasOwnProperty(""))
            if (snapshotEdited["data_grips"] === undefined) {
              snapshotEdited["data_grips"] = {
                tripod: 0,
                power: 0,
                trigger: 0,
                precision_open: 0,
                precision_closed: 0,
                key: 0,
                finger_point: 0,
                mouse: 0,
                column: 0,
                relaxed: 0,
                glove: 0,
                tap: 0,
                phone: 0,
                rock: 0,
                user_grip_1: 0,
                user_grip_2: 0,
                user_grip_3: 0,
                user_grip_4: 0,
                user_grip_5: 0,
                user_grip_6: 0,
              }
            }

          Object.entries({
            ...schemaFromConfigHex(configHex105).statistics,
            ...schemaFromConfigHex(configHex106).statistics,
            ...schemaFromConfigHex(configHex107).statistics,
          }).forEach((section) => {
            section[0] !== "data_limits" &&
              section[1].options.forEach((stat) => {
                if (
                  "title" in stat &&
                  statSnapshot.data()[section[0]] &&
                  ![
                    "thumb_in",
                    "thumb_out",
                    "thumb_further_in",
                    "thumb_further_out",
                    "finger_tap",
                    "dorsal_hold",
                  ].includes(stat.title)
                ) {
                  const thisVal = parseInt(statSnapshot.data()[section[0]][stat.title])
                  if (!first) {
                    snapshotEdited[section[0]][stat.title] = thisVal | 0
                  } else {
                    const initialVal = parseInt(first!.data()[section[0]][stat.title])
                    if (thisVal > initialVal) {
                      snapshotEdited[section[0]][stat.title] = thisVal - initialVal
                    } else if (thisVal < initialVal) {
                      snapshotEdited[section[0]][stat.title] = thisVal
                    } else {
                      snapshotEdited[section[0]][stat.title] = 0
                    }
                  }
                }
              })
          })
          handStats.set(statSnapshot.id, snapshotEdited as StatisticsWithConfigInfo)
        })
        resolve({ [handId]: handStats })
      })
      .catch((e) => {
        console.error("Failed to get stats", e)
        reject()
      })
  })
}

export const getGeneralStats = (profile: ProfileData) => {
  return new Promise<ShortSerialStats>(async (resolve, reject) => {
    const startTime = Date.now()
    let handsToIgnore = [
      // default hand & Dave's default
      "0000",
      "1234",
      // cycle tested hands
      "0024",
      "1158",
      "1128",
      "1343",
      "1320",
    ]
    let allHandsObj: SingleHandStats = {}
    let shortSerialStats: ShortSerialStats

    let latest: StorageReference | undefined
    let hands: string[] = []

    const replacer = (key: string, value: any) => {
      if (value instanceof Map) {
        return {
          dataType: "Map",
          value: Array.from(value.entries()), // or with spread: value: [...value]
        }
      } else {
        return value
      }
    }

    const reviver = (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
          return new Map(value.value)
        }
      }
      return value
    }

    const mergeHandsBySerial = (statsMapObj: SingleHandStats) => {
      const handsObj: SingleHandStats = {}
      Object.entries(statsMapObj).forEach(([serial, entry], i) => {
        for (const [date, statsObj] of entry) {
          const shortSerial = serial.substring(serial.length - 4, serial.length)
          if (!handsObj[shortSerial]) {
            handsObj[shortSerial] = new Map()
          }
          handsObj[shortSerial].set(date, statsObj)
        }
      })
      return handsObj
    }

    const consolidatedConfigs = (statsMapObj: SingleHandStats) => {
      const returnObj: ShortSerialStats = {}
      Object.entries(statsMapObj).forEach(([serial, entry], i) => {
        const shortSerial = serial.substring(serial.length - 4, serial.length)
        if (!returnObj[shortSerial]) {
          returnObj[shortSerial] = new Map()
        }
        let statsArray = Array.from(entry)
        statsArray.forEach(([date, statsObj], i) => {
          if (i === 0) {
            returnObj[shortSerial].set(date, statsObj)
          } else {
            let currentObj: StatsPoss = {
              data_counter: {},
              data_triggers: {},
              data_limits: {},
              data_usage: {},
              data_grips: {},
            }
            Object.entries(statsObj).forEach(([key, value]) => {
              const keyNamed = key as keyof StatisticsWithConfigInfo
              const prev = statsArray[i - 1][1]
              const cur = statsArray[i][1]
              if (keyNamed === "config_hex" && prev.config_hex !== cur.config_hex) {
                currentObj.config_hex = cur.config_hex
              }
              if (["config_hex", "set_by", "user_grips"].includes(keyNamed)) {
                return
              }
              if (keyNamed === "config_hex" && prev.config_hex !== cur.config_hex) {
                currentObj.config_hex = cur.config_hex
              } else {
                Object.entries(value).forEach(([statsParam, numVal]) => {
                  const statVal = numVal as number
                  switch (keyNamed) {
                    case "data_usage":
                      const dUsage = statsParam as keyof DataUsage
                      if (cur.data_usage[dUsage] !== prev.data_usage[dUsage])
                        currentObj.data_usage[dUsage] = statVal
                      break
                    case "data_grips":
                      const dGrips = statsParam as keyof DataGrips
                      if (prev.data_grips === undefined) {
                        currentObj.data_grips[dGrips] = statVal
                      } else if (cur.data_grips[dGrips] !== prev.data_grips[dGrips])
                        currentObj.data_grips[dGrips] = statVal
                      break
                    case "data_counter":
                      const dCounter = statsParam as keyof DataCounter
                      if (cur.data_counter[dCounter] !== prev.data_counter[dCounter])
                        currentObj.data_counter[dCounter] = statVal
                      break
                    case "data_triggers":
                      const dTriggers = statsParam as keyof DataTriggers
                      if (cur.data_triggers[dTriggers] !== prev.data_triggers[dTriggers])
                        currentObj.data_triggers[dTriggers] = statVal
                      break
                    case "data_limits":
                      const dLimits = statsParam as keyof DataLimits
                      if (cur.data_limits[dLimits] !== prev.data_limits[dLimits])
                        currentObj.data_limits[dLimits] = statVal
                      break
                    default:
                      console.warn("Error:", keyNamed, key, serial)
                      break
                  }
                })
              }
            })
            returnObj[shortSerial].set(date, currentObj)
          }
        })
      })
      return returnObj
    }

    const getInt = (ref: StorageReference) => parseInt(ref.name.split("_")[0])

    await listAll(ref(storage, "CachedStatsSnapshots/")).then((result) =>
      result.items.forEach((ref) => {
        if (!latest || getInt(ref) > getInt(latest)) {
          latest = ref
        }
      })
    )

    if (!latest || (latest && getInt(latest) + 604800000 < Date.now())) {
      // comment this ^^ to bypass stats caching
      await getDocs(query(collection(firestore, `Hands`))).then(
        async (res) =>
          await Promise.all(
            res.docs
              .map((hand) => hand.id)
              .map((hand) => updateHandField(hand, "AppStatistics", "latest_stats"))
          )
      )
      await getDocs(query(collection(firestore, `Hands`), where("latest_stats", "!=", null)))
        .then((querySnapshot) => querySnapshot.forEach((doc) => hands.push(doc.id)))
        .catch((e) => reject(`Couldn't get Stats: ${e}`))

      hands = hands.filter(
        (handId) =>
          !(handId.startsWith("CV1") && handsToIgnore.some((hand) => handId.endsWith(hand)))
      )

      await Promise.all(
        hands.map((hand) =>
          getStats({
            handId: hand,
            role: profile.role,
            uid: profile.uid,
            associatedUsers: profile.associated_users,
          })
        )
      )
        .then((res) =>
          res
            .filter((handStatsObj) => Object.values(handStatsObj)[0].size > 0)
            .forEach((hand) => {
              allHandsObj[Object.keys(hand)[0]] = Object.values(hand)[0]
            })
        )
        .catch((e) => reject(`Couldn't get Stats: ${e}`))

      allHandsObj = mergeHandsBySerial(allHandsObj)
      shortSerialStats = consolidatedConfigs(allHandsObj)

      if (shortSerialStats) {
        resolve(shortSerialStats)
        uploadBytes(
          ref(storage, `CachedStatsSnapshots/${Date.now()}.json`),
          new Blob(
            [
              JSON.stringify(
                {
                  set_by: profile.uid,
                  shortSerialStats: shortSerialStats,
                  date: new Date().toISOString().replace("T", " ").split(".")[0],
                },
                replacer
              ),
            ],
            { type: "application/json" }
          )
        ).then(() => console.log("TIME TAKEN TO COMPLETE:", Date.now() - startTime))
      } else {
        reject(`Couldn't retrieve stats for hands: ${hands}`)
      }
    } else {
      // comment this else block ^^ to bypass stats caching
      await getBlob(latest)
        .then(async (blob) => await blob.text())
        .then(async (json) => await JSON.parse(json, reviver))
        .then((statsSnaphot) => resolve(statsSnaphot.shortSerialStats))
        .catch(() => reject("Error retrieving latest cached stats"))
    }
  })
}
