import { useEffect, useState } from "react"

import { useAuth } from "@context/AuthContext"
import { useNavigate } from "react-router-dom"
import extractHandInfo from "../../util/commonFunctions/extractHandInfo"
import { DefaultObject, NewTableOptions, TableData } from "@typesFolder/types"

import Table from "@ui/table/Table"
import { setCovviUids } from "@util/firebase/userFunctions"
import { AllStatsOverview } from "@pages/hands/AllStatsOverview"
import { AllErrorsOverview } from "./AllErrorsOverview"
import useWindowSize from "@util/hooks/useWindowSize"
import { languageSelector as ls } from "@covvi/language-selector"
import Spinner from "@ui/spinners/Spinner"
import { getListOfHands, getMyHands } from "@util/firebase/handFunctions/configFunctions"
import { logEvent } from "firebase/analytics"
import { analytics } from "@util/firebase/firebase"

const Hands = () => {
  const { profile } = useAuth()
  const { width = 1024 } = useWindowSize()
  const [hands, setHands] = useState<TableData[]>([])
  const [tabSelectValue, setTabSelectValue] = useState<"All Hands" | "Statistics" | "Errors">(
    "All Hands"
  )
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const openHand = (data: DefaultObject): void => {
    navigate(`/hands/${data.id}`)
  }

  useEffect(() => logEvent(analytics, "page_hands", { uid: profile?.uid }), [])

  useEffect(() => {
    setLoading(true)
    if (profile) {
      setCovviUids()
      if (
        ["Sales Team Member", "Customer Service Team Member", "Tech Team Member", "Admin"].includes(
          profile.role
        )
      ) {
        getListOfHands(profile)
          .then((res) => {
            setLoading(false)
            setHands(
              res
                .map(extractHandInfo)
                .sort((handA, handB) => (handB.latestConfig || 0) - (handA.latestConfig || 0))
            )
          })
          .catch((error) => {
            console.error(error)
          })
      } else if (["Clinician", "User"].includes(profile.role)) {
        getMyHands(profile)
          .then((res) => {
            setLoading(false)

            setHands(res.map(extractHandInfo))
          })
          .catch((e) => {})
      }
    }
  }, [profile])

  const adminOptions = {
    buttons: [
      {
        title: "All Hands",
        onClick: () => setTabSelectValue("All Hands"),
      },
      {
        title: "Statistics",
        onClick: () => setTabSelectValue("Statistics"),
      },
      {
        title: "Errors",
        onClick: () => setTabSelectValue("Errors"),
      },
    ],
    selected: tabSelectValue,
    setSelected: () => setTabSelectValue,
  }

  const covviTableOptions: NewTableOptions = {
    fieldIds: [
      "id",
      "latestConfig",
      "latestStats",
      "latestErrors",
      "latestCustomerConnection",
      "latestCovviConnection",
      "latestInvalid_config",
    ],
    fieldTitles: [
      ls.getText("Serial Number"),
      ls.getText("latest_config_change"),
      ls.getText("latest_stats"),
      ls.getText("latest_errors"),
      ls.getText("latest_customer_connection"),
      ls.getText("latest_covvi_connection"),
      ls.getText("latest_invalid_config"),
    ],
    searchParams: [
      "id",
      "latestConfig",
      "latestStats",
      "latestErrors",
      "latestCustomerConnection",
      "latestInvalid_config",
      "latestCovviConnection",
    ],
    ...(["Admin"].includes(profile!.role) && {
      pillArray: adminOptions,
    }),
    onClick: openHand,
  }

  const tableOptions: NewTableOptions = {
    fieldIds: ["id", ...(width > 640 ? ["laterality", "size", "color"] : [])],
    fieldTitles: [
      "Serial Number",
      ...(width > 640 ? [ls.getText("Laterality"), ls.getText("Size"), ls.getText("Color")] : []),
    ],
    onClick: openHand,
    searchParams: ["id", ...(width > 640 ? ["size", "color"] : [])],
  }

  switch (tabSelectValue) {
    case "Statistics":
      return <AllStatsOverview pillArray={adminOptions} />
    case "Errors":
      return <AllErrorsOverview pillArray={adminOptions} />
    default:
      return loading ? (
        <Spinner />
      ) : (
        <Table
          data={hands}
          tableOptions={
            [
              "Admin",
              "Tech Team Member",
              "Sales Team Member",
              "Customer Service Team Member",
            ].includes(profile!.role)
              ? covviTableOptions
              : tableOptions
          }
        />
      )
  }
}

export default Hands
