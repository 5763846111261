import { Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppBar, IconButton, Toolbar } from "@mui/material"
import { Dialog, Transition } from "@headlessui/react"
import MenuIcon from "@mui/icons-material/Menu"
import { CubeIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline"
import {
  HandRaisedIcon,
  UsersIcon,
  ComputerDesktopIcon,
  TicketIcon,
  UserCircleIcon,
  LanguageIcon,
} from "@heroicons/react/24/solid"
import { languageSelector as ls } from "@covvi/language-selector"
import logo from "@assets/images/logo.png"
import NavigationStructure from "../../../assets/Navigation.json"
import { NavType } from "@typesFolder/types"
import { useAuth } from "@context/AuthContext"

export const MobileNav = ({ title }: { title: string }) => {
  const { profile, signOut } = useAuth()
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false)
  const navigation: NavType = NavigationStructure
  const navigate = useNavigate()

  const Icon = ({ section, styling }: { section: string; styling: string }) => {
    switch (section) {
      case "Account Management":
        return <UserCircleIcon className={styling} />
      case "Users":
        return <UsersIcon className={styling} />
      case "Hands":
        return <HandRaisedIcon className={styling} />
      case "Support Tickets":
        return <TicketIcon className={styling} />
      case "Remote Assist":
        return <ComputerDesktopIcon className={styling} />
      case "Returns":
        return <CubeIcon className={styling} />
      case "Translations":
        return <LanguageIcon className={styling} />
      case "Sign Out":
        return <ArrowRightOnRectangleIcon className={styling} />
      default:
        return <></>
    }
  }
  return (
    <>
      <div className="absolute left-0 top-0 flex items-center lg:hidden w-full ">
        <div className="z-50 w-full">
          <AppBar position="static">
            <Toolbar style={{ background: "black" }}>
              <IconButton
                size="large"
                edge="start"
                color="secondary"
                aria-label="menu"
                sx={{ position: "absolute" }}
                onClick={() => setNavBarOpen(!navBarOpen)}
              >
                <MenuIcon />
              </IconButton>
              <img className="mx-auto h-14 w-auto my-2" src={logo} alt="Workflow" />
            </Toolbar>
          </AppBar>
        </div>
        <Transition.Root show={navBarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full"
            >
              <div className="relative flex-1 flex flex-col w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -mr-12 pt-2"></div>
                </Transition.Child>
                <div className="flex-1 overflow-y-auto">
                  <nav className="flex-1 mt-20 space-y-1 ">
                    {profile &&
                      Object.keys(navigation)
                        .filter((a) => {
                          return navigation[a].userLevel.includes(profile.role)
                        })
                        .filter((a) =>
                          navigation[a].href === "/translation" && profile?.role === "Clinician"
                            ? profile?.languages && profile?.languages.length > 0
                            : true
                        )
                        .map((item, key) => (
                          <div
                            key={key}
                            onClick={() => {
                              navigate(`${navigation[item].href}`)
                              setNavBarOpen(false)
                            }}
                            className={`${
                              title === item
                                ? "bg-copper text-white"
                                : "text-darkCovviGrey hover:text-black"
                            } group flex items-center px-10 py-4 text-base font-medium mt-1`}
                          >
                            <Icon section={item} styling={" mr-4 flex-shrink-0 h-8 w-8"} />
                            {item}
                          </div>
                        ))}
                    <div
                      onClick={signOut}
                      className={`${
                        title === "Sign Out"
                          ? "bg-copper text-black"
                          : "text-black hover:bg-red-700"
                      }
                    group flex items-center px-10 py-4 text-base font-medium mt-1  fixed bottom-0 w-full`}
                    >
                      <div className="bg-gray-100 w-2/3 mx-auto flex py-2 px-6 rounded-xl">
                        <Icon section="Sign Out" styling={"mr-4 flex-shrink-0 h-8 w-8"} />
                        <p className="my-auto"> {ls.getText("Sign Out")}</p>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  )
}
