import { useEffect, useState } from "react"
import { Config, getStart, replaceAt, UpdateUserGrip, UserGrip } from "@covvi/common-functions"
import { ButtonObj, ConfigSectionInterface } from "@typesFolder/types"
import GripTables from "./handConfigSection/GripTables"
import GripSwitchTriggers from "./handConfigSection/GripSwitchTriggers"
import ConfigSystemInfoTab from "./ConfigSystemInfoTab"
import ConfigActions from "./handConfigSection/ConfigActions"
import HandControl from "./handConfigSection/HandControl"
import InputSettings from "./handConfigSection/InputSettings"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { SecondaryTabArray } from "@ui/sections/SecondaryTabArray"

interface Props {
  config: Config | undefined
  setCurrentConfig?: (configHex: string) => void | undefined
  canEdit?: boolean
  isLiveRemoteAssist?: boolean
  buttonArray?: ButtonObj[]
  isLoading?: boolean
}

const HandConfigSection = ({
  config,
  setCurrentConfig = undefined,
  canEdit = true,
  isLiveRemoteAssist = false,
  buttonArray,
  isLoading,
}: Props) => {
  const [selected, setSelected] = useState("hand_control")
  const [content, setContent] = useState<JSX.Element>()

  const roundedBottom =
    !canEdit || selected === "Config Actions" || selected === "System Information"
  const showFooter =
    buttonArray && canEdit && selected !== "Config Actions" && selected !== "System Information"
  const tabs = [
    "hand_control",
    "input_settings",
    "grip_tables",
    "grip_switch_triggers",
    "System Information",
    ...(isLiveRemoteAssist ? ["Config Actions"] : []),
  ]

  const updateParameter = (paramName: string, hexVal: string) => {
    const start = getStart(paramName, config?.configHex)
    config && setCurrentConfig && setCurrentConfig(replaceAt(config.configHex, start, hexVal))
  }
  const addUserGrip = (grip: UpdateUserGrip) => {}
  const editUserGrips = (grip: UserGrip, updateCommands: string[]) => {}

  useEffect(() => {
    if (!config) return

    const sharedProps: ConfigSectionInterface = {
      configHex: config.configHex,
      canEdit: canEdit,
      userGrips: config.userGrips,
      updateParameter,
      isLoading,
    }
    switch (selected) {
      case "hand_control":
        return setContent(<HandControl {...sharedProps} />)
      case "input_settings":
        return setContent(<InputSettings {...sharedProps} />)
      case "grip_tables":
        return setContent(
          <GripTables
            {...sharedProps}
            setConfigHex={setCurrentConfig}
            addUserGrip={(grip) => addUserGrip(grip)}
            editUserGrips={(grip, updateCommands) => editUserGrips(grip, updateCommands)}
          />
        )
      case "grip_switch_triggers":
        return setContent(<GripSwitchTriggers {...sharedProps} />)
      case "System Information":
        return setContent(<ConfigSystemInfoTab config={config} />)
      case "Config Actions":
        return setContent(<ConfigActions config={config} />)
      default:
        return setContent(<></>)
    }
  }, [selected, config, isLoading])

  return (
    <div className="flex flex-col sm:items-start">
      <SecondaryTabArray
        buttonArray={tabs.map((tab) => ({ title: tab }))}
        selected={selected}
        setSelected={(title) => !isLoading && setSelected(title)}
        isWithinCard={false}
      />
      <div className="w-full mt-4">
        <CVCardBody roundedTop={true} roundedBottom={roundedBottom}>
          <div className={`w-full mt-2 grid md:grid-cols-1 lg:grid-cols-2gap-x-10 p-2 md:p-10`}>
            {content}
          </div>
        </CVCardBody>
        {showFooter && (
          <CVCardFooter>
            <ButtonRow buttonArray={buttonArray} />
          </CVCardFooter>
        )}
      </div>
    </div>
  )
}

export default HandConfigSection
