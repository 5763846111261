import { useCurrentHandContext } from "@context/remoteAssistProviders"
import { getVal, toHex } from "@covvi/common-functions"
import { Slider } from "@mui/material"
import { DigitPositions } from "@typesFolder/remoteAssistTypes"
import { SyntheticEvent, useEffect, useState } from "react"

const DigitPositionIndicator = ({
  sendCommand,
  title,
  index,
  configHex,
}: {
  sendCommand: (command: string) => void
  title: string
  index: string
  configHex: string
}) => {
  const { handStatus } = useCurrentHandContext()
  const { digitPositions } = useCurrentHandContext()

  const [value, setValue] = useState(0)
  const [marks, setMarks] = useState<{ value: number; label: string }[]>([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [endStops, setEndStops] = useState<{
    open: number
    closed: number
  }>()

  useEffect(() => {
    if (!handStatus?.currentGrip) {
      return
    }
    const openEndpoint = getVal(`${title.toLowerCase()}_open_endpoint`, configHex).int
    const closedEndpoint = getVal(`${title.toLowerCase()}_close_endpoint`, configHex).int
    const markArray = [0, openEndpoint, closedEndpoint]
    const pinchArray = ["Tripod", "Precision Open", "Precision Closed"]

    if (pinchArray.includes(handStatus.currentGrip)) {
      title === "Thumb" && markArray.push(getVal("pinch_thumb_pos", configHex).int)
      title === "Index" && markArray.push(getVal("pinch_index_pos", configHex).int)
      title === "Middle" &&
        handStatus?.currentGrip === "Tripod" &&
        markArray.push(getVal("pinch_middle_pos", configHex).int)
      if (["Precision Open", "Precision Closed"].includes(handStatus.currentGrip)) {
        title === "Rotate" && markArray.push(getVal("pinch_rotate_1", configHex).int)
      } else {
        title === "Rotate" && markArray.push(getVal("pinch_rotate_2", configHex).int)
      }
    }
    if (handStatus.currentGrip === "Key") {
      title === "Thumb" && markArray.push(getVal("key_thumb", configHex).int)
      title === "Index" && markArray.push(getVal("key_index", configHex).int)
    }

    title === "Thumb" &&
      handStatus.currentGrip === "Column" &&
      markArray.push(getVal("column_thumb", configHex).int)

    setEndStops({ open: openEndpoint, closed: closedEndpoint })
    setMarks(markArray.map((n) => ({ value: n, label: n.toString() })))
  }, [handStatus, configHex])

  useEffect(() => {
    if (!digitPositions || isUpdating) {
      return
    } else {
      setValue(
        digitPositions[
          `${title !== "Rotate" ? title.toLowerCase() : "rotation"}Position` as keyof DigitPositions
        ]
      )
    }
  }, [digitPositions])

  const changePosition = (_: Event, newValue: number | number[]) => {
    setIsUpdating(true)
    const updateVal = newValue as number
    endStops && updateVal > endStops.open && updateVal < endStops.closed && setValue(updateVal)
  }

  const setPosition = (_: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    setIsUpdating(false)
    ;["TT0101", `TM03${index}${toHex(newValue as number)}64`, "TT0100"].forEach((command, i) =>
      setTimeout(() => sendCommand(command), i * 250)
    )
  }

  return endStops ? (
    <div className="h-60 flex flex-row rounded-[5px] border border-[#E9E9E9] p-5">
      <div className="text-md flex-1">{title}</div>
      <Slider
        orientation="vertical"
        valueLabelDisplay="on"
        value={value}
        marks={marks}
        max={endStops.closed}
        onChange={changePosition}
        onChangeCommitted={setPosition}
      />
    </div>
  ) : (
    <></>
  )
}

export default DigitPositionIndicator
