import { Config, ConfigVersion, ProfileData } from "@covvi/common-functions"
import { FireStoreHand } from "@typesFolder/handTypes"
import { HandFirestoreData } from "@typesFolder/types"
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore"
import { firestore } from "../firebase"
import { getCovviUids, getMyUsers, setCovviUids } from "../userFunctions"
import { getLastSixFromSerial } from "./handFunctions"
import { languageSelector as ls } from "@covvi/language-selector"

const getLatestConfig = (hand: FireStoreHand): Promise<FireStoreHand> =>
  getDoc(doc(firestore, `Hands/${hand.serialNumber}/Configs/Live Config`))
    .then((res) => {
      const liveConfig = res.data()
      if (!liveConfig) {
        return hand
      } else {
        return {
          ...hand,
          latest_config: liveConfig.date.seconds,
          latestConfigString: liveConfig.configHex,
        }
      }
    })
    .catch(() => hand)

export const getListOfHands = (profile: ProfileData) => {
  return new Promise<FireStoreHand[]>(async (resolve, reject) => {
    if (["Customer Service Team Member", "Tech Team Member", "Admin"].includes(profile.role)) {
      let hands: FireStoreHand[] = []
      await getDocs(query(collection(firestore, "Hands")))
        .then((snap) =>
          snap.forEach((doc) =>
            hands.push({ serialNumber: doc.id, ...doc.data() } as FireStoreHand)
          )
        )
        .catch((e) => console.log(e))
      Promise.all(hands.map((hand) => getLatestConfig(hand)))
        .then(resolve)
        .catch((e) => reject(e))
    } else {
      reject("insufficient privilege")
    }
  })
}

export const getMyHands = (profile: ProfileData) => {
  return new Promise<FireStoreHand[]>(async (resolve, reject) => {
    let associatedHandsStrings: string[] = profile.associated_hands
      ? profile.associated_hands.map((serial) => serial.slice(-6))
      : []
    let associatedHands: FireStoreHand[] = []

    if (profile?.associated_users) {
      await getMyUsers(profile.uid, profile.role).then((users) => {
        users.forEach((user) => {
          if (user.associated_hands) {
            associatedHandsStrings = [
              ...associatedHandsStrings,
              ...(user.associated_hands as string[]).map((hand) => hand.slice(-6)),
            ]
          }
        })
      })
    }
    const associatedHandsLastSix = [
      ...new Set(
        await Promise.all(
          [...new Set(associatedHandsStrings)].map((serial) => getLastSixFromSerial(serial))
        )
      ),
    ]

    let chunkArray: string[][] = []
    chunkArray = [...Array(Math.ceil(associatedHandsLastSix.length / 30))].map((_) =>
      associatedHandsLastSix.splice(0, 30)
    )

    const getHandsByChunk = (chunk: string[]) =>
      getDocs(query(collection(firestore, "Hands"), where(documentId(), "in", chunk)))
        .then((snap) =>
          snap.forEach((doc) =>
            associatedHands.push({ serialNumber: doc.id, ...doc.data() } as FireStoreHand)
          )
        )
        .catch((e) => console.log(e))
    await Promise.all(chunkArray.map((chunk) => getHandsByChunk(chunk)))
    Promise.all(associatedHands.map((hand) => getLatestConfig(hand)))
      .then(resolve)
      .catch((e) => reject(e))
  })
}

export const getHandConfigs: (forHand: string, profile: ProfileData) => Promise<Config[]> = (
  forHand,
  profile
) => {
  return new Promise(async (resolve, reject) => {
    let acceptableUids: string[] = [profile.uid, "Unknown"]
    const isCovvi = [
      "Admin",
      "Tech Team Member",
      "Sales Team Member",
      "Customer Service Team Member",
    ].includes(profile.role)
    const ordered = (configs: Config[]) =>
      configs.sort((a, b) => {
        if (isNaN(Date.parse(a.date.toString())) && isNaN(Date.parse(b.date.toString()))) {
          return 0
        } else if (isNaN(Date.parse(b.date.toString()))) {
          return -1
        } else if (isNaN(Date.parse(a.date.toString()))) {
          return 1
        } else
          return Date.parse(b.date.toString()).valueOf() - Date.parse(a.date.toString()).valueOf()
      })
    if (!isCovvi) {
      let covviUids: string[] = getCovviUids()
      if (!covviUids.length) {
        covviUids = await setCovviUids()
      }
      acceptableUids = acceptableUids.concat(covviUids)
      profile.clinician && acceptableUids.push(profile.clinician)
      if (profile.associated_users) {
        acceptableUids = acceptableUids.concat(profile.associated_users)
      }
    }
    const configLibrary = await getDocs(collection(firestore, `Hands/${forHand}/Configs`)).then(
      (querySnapshot) => {
        const configs: Config[] = []
        querySnapshot.forEach((config) => {
          const validConfig = config.data() as Config

          if (validConfig) {
            if (validConfig.name === "Live Config") {
              configs.push({ ...validConfig, isLive: true })
            } else {
              configs.push(validConfig)
            }
          }
        })
        return isCovvi
          ? ordered(configs)
          : ordered(configs.filter((config) => acceptableUids.includes(config.setBy)))
      }
    )
    resolve(configLibrary)
  })
}

export const getHandData: (serialNumber: string) => Promise<HandFirestoreData | undefined> = (
  serialNumber
) => {
  return new Promise((resolve, reject) => {
    getDoc(doc(firestore, "Hands", serialNumber))
      .then((data) => {
        resolve(data.data())
      })
      .catch((e) => {
        reject(`${e}, - Failed to fetch hand data`)
      })
  })
}

export const uploadConfigHistory = (
  {
    configHex,
    configVersion,
    firmwareVersion,
    serialNumber,
    name,
    setBy,
    userGrips,
    archivedBy,
    userAppVersion,
    configImportedFrom,
  }: Config,
  profile: ProfileData
) => {
  return new Promise<true>(async (resolve, reject) => {
    const data = {
      name,
      configHex,
      firmwareVersion,
      configVersion,
      serialNumber,
      setBy,
      userGrips,
      archivedBy,
      date: new Timestamp(new Date().getTime() / 1000, 0),
      userAppVersion,
      configImportedFrom,
    } as Config
    const upload = async (configToUpload: Config) => {
      let filteredData: Partial<Config> = {}
      Object.entries(configToUpload).forEach(([k, v]) => {
        if (v !== undefined) {
          filteredData[k as keyof Config] = v
        }
      })
      setDoc(doc(firestore, `Hands/${serialNumber}/Configs`, configToUpload.name), filteredData, {
        merge: true,
      })
    }
    const overwritableUids: string[] | undefined = profile.associated_users
      ? [profile.uid, ...profile.associated_users]
      : undefined
    const existingEntry = (
      await getDoc(doc(firestore, `Hands/${serialNumber}/Configs/${name}`))
    ).data()
    if (existingEntry === undefined) {
      upload(data)
        .then(() => resolve(true))
        .catch(() => reject("error"))
    } else if (
      overwritableUids?.includes(existingEntry.setBy) ||
      ["Admin", "Tech Team Member"].includes(profile.role)
    ) {
      upload({
        ...(existingEntry as Config),
        name: `ARCHIVED_${Date.now().toString()}_${existingEntry.name}`,
        archivedBy: profile.uid,
      })
        .then(() => upload(data))
        .then(() => resolve(true))
        .catch(() => reject("error"))
    } else {
      reject("error")
    }
  })
}

export const deleteConfig = (config: Config, serialNumber: string, profile: ProfileData) => {
  return new Promise((resolve, reject) => {
    if (config.setBy !== profile.uid && !["Admin", "Tech Team Member"].includes(profile.role)) {
      reject("Insufficient Permission")
      return
    }
    if (config.name && !config.name.startsWith("ARCHIVED")) {
      const configToArchive = {
        configHex: config.configHex,
        configVersion: config.configVersion?.toString() as ConfigVersion | undefined,
        firmwareVersion: config.firmwareVersion || ls.getText("Unknown"),
        serialNumber: serialNumber,
        name: `ARCHIVED_${Date.now().toString()}_${config.name}`,
        setBy: config.setBy,
        userGrips: config.userGrips && config.userGrips,
        archivedBy: profile.uid,
        date: config.date || new Date(),
        userAppVersion: config.userAppVersion || ls.getText("Unknown"),
      }
      uploadConfigHistory(configToArchive, profile)
        .then((res) => {
          resolve(res)
          deleteDoc(doc(firestore, `Hands/${serialNumber}/Configs/${config.name}`))
            .then(() => resolve("Config Deleted"))
            .catch((e) => reject("Error Deleting Config"))
        })
        .catch((e) => reject("Error Archiving Config"))
    } else {
      deleteDoc(doc(firestore, `Hands/${serialNumber}/Configs/${config.name}`))
        .then(() => resolve("Config Deleted"))
        .catch((e) => reject("Error Deleting Config"))
    }
  })
}
