import { firestore, functions } from "../firebase"

import { collection, getDocs, getDoc, doc, updateDoc, arrayUnion, setDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { languageSelector as ls } from "@covvi/language-selector"
import { getHandData } from "./configFunctions"
import { ProfileData } from "@covvi/common-functions"

export const getFittingDate: (serialNumber: string) => Promise<string> = (serialNumber) => {
  return new Promise((resolve, reject) => {
    getHandData(serialNumber)
      .then((handData) => {
        if (
          handData?.connection_events === undefined ||
          handData?.connection_events[handData.connection_events.length - 1].type !==
            "First connection since service"
        ) {
          resolve(ls.getText("Unknown Date"))
        } else {
          let date = new Date(
            handData?.connection_events[handData.connection_events.length - 1].date.seconds * 1000
          ).toISOString()

          resolve(date)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const updateHandField = (hand: string, attributeCollection: string, attribute: string) => {
  return new Promise((resolve) => {
    getDocs(collection(firestore, `Hands/${hand}/${attributeCollection}`))
      .then((querySnapshot) => {
        if (querySnapshot.size) {
          const timeStamps = Array.from(querySnapshot.docs)
            .map((doc) => doc.id)
            .sort((a, b) => parseInt(b) - parseInt(a))
          setDoc(
            doc(firestore, "Hands", hand),
            {
              [attribute]: timeStamps[0],
            },
            { merge: true }
          )
          resolve(true)
        } else resolve(false)
      })
      .catch((e) => resolve(false))
  })
}

export const addAssociatedHand = (profileUid: string, serialNumberToAdd: string) => {
  return new Promise((resolve, reject) => {
    updateDoc(doc(firestore, "Users", profileUid), {
      associated_hands: arrayUnion(serialNumberToAdd),
    })
      .then(resolve)
      .catch(reject)
  })
}

export const sendConfigVersion100ProbableErrorEmail = (
  profile: ProfileData,
  serialNumber: string,
  configHex: string,
  remoteAssistRoom?: string
) => {
  return new Promise<string>(async (resolve, reject) => {
    const config100email = httpsCallable(functions, "configVersion100ProbableError")
    let emailData = {
      role: profile.role,
      name: `${profile.first_name} ${profile.last_name}`,
      emailAddress: profile.email_address,
      userUid: profile.uid,
      serialNumber: serialNumber,
      remoteAssistRoom: remoteAssistRoom,
      date: new Date().toString(),
      configHex: configHex,
    }

    config100email(emailData)
      .then(() => resolve("Config version 100 probable error email sent."))
      .catch((e) => reject(`${e} - Failed to send config version 100 email`))
  })
}

export const getLastSixFromSerial = (serial: string) => {
  return new Promise<string>(async (resolve, reject) => {
    const lastSixDigits = serial.slice(-6)
    let lastFourDigits = lastSixDigits.slice(2)
    let currentYear = new Date().getFullYear().toString().slice(2)
    for (let i = 19; i <= parseInt(currentYear); i++) {
      try {
        await getDoc(doc(firestore, "Hands", `${i}${lastFourDigits}`)).then(
          (doc) => doc.exists() && resolve(`${i}${lastFourDigits}`)
        )
      } catch {
        console.warn("Error looking up firebase collection")
      }
    }
    return resolve(lastSixDigits)
  })
}
