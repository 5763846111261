import { toASCIIString, toInt } from "@covvi/common-functions"
import { FireStoreHand } from "@typesFolder/handTypes"
import { Hand } from "@typesFolder/types"

const extractHandInfo = (firestoreHand: FireStoreHand) => {
  let handObj: Hand = { id: firestoreHand.serialNumber }

  if (firestoreHand.latestConfigString) {
    const hex = firestoreHand.latestConfigString
    handObj.laterality = toInt(hex.substring(0, 2)) === 1 ? "Left" : "Right"
    handObj.size =
      toInt(hex.substring(2, 4)) === 1
        ? "small"
        : toInt(hex.substring(2, 4)) === 2
        ? "medium"
        : "large"

    if (toASCIIString(hex.substring(46, 50)) === "CA") {
      handObj.color = "Carbon Black"
    }
    if (toASCIIString(hex.substring(46, 50)) === "WH") {
      handObj.color = "White"
    }
    if (toASCIIString(hex.substring(46, 50)) === "TG") {
      handObj.color = "Titan Grey"
    }
    if (toASCIIString(hex.substring(46, 50)) === "RG") {
      handObj.color = "Rose Gold"
    }
  }

  handObj.latestConfig = firestoreHand.latest_config ? firestoreHand.latest_config * 1000 : 0

  if (!firestoreHand.connection_events) {
    handObj.latestCovviConnection = 0
    handObj.latestCustomerConnection = 0
  } else {
    const covviConnections = firestoreHand.connection_events?.filter(
      (event) => event.type === "COVVI service"
    )

    handObj.latestCovviConnection =
      covviConnections.length >= 1
        ? covviConnections.sort((a, b) => {
            if (a.date.seconds < b.date.seconds) return -1
            if (a.date.seconds > b.date.seconds) return 1
            return 0
          })[0].date.seconds * 1000
        : 0

    const userConnections = firestoreHand.connection_events?.filter((event) =>
      ["User Connection", "First connection since service"].includes(event.type)
    )

    handObj.latestCustomerConnection =
      userConnections.length >= 1
        ? userConnections.sort((a, b) => {
            if (a.date.seconds < b.date.seconds) return -1
            if (a.date.seconds > b.date.seconds) return 1
            return 0
          })[0].date.seconds * 1000
        : 0
  }

  handObj.latestInvalid_config = firestoreHand.latest_invalid_config || 0
  handObj.latestStats = firestoreHand.latest_stats
  handObj.latestErrors = firestoreHand.latest_errors
  return handObj
}

export default extractHandInfo
