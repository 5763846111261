import { useEffect, useState } from "react"
import { Alert, AlertColor } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"

import UserCard from "@components/cards/UserCard"
import BasicModal from "@ui/modals/BasicModal"
import Spinner from "@ui/spinners/Spinner"
import DataCollection from "@components/modals/myAccount/DataCollection"
import ConfirmRequestDeletion from "@components/modals/myAccount/ConfirmRequestDeletion"
import ResetPassword from "@components/sections/ResetPassword"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardBody from "@ui/sections/CVCardBody"
import { PillButtonArray } from "@ui/sections/PillButtonArray"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { logEvent } from "firebase/analytics"
import { analytics } from "@util/firebase/firebase"

const MyAccount = () => {
  const { profile, signOut, resetPW } = useAuth()
  const [success, setSuccess] = useState<string>()
  const [selectedTab, setSelectedTab] = useState<"User" | "Security">("User")
  const [showingDataCollection, setShowingDataCollection] = useState<boolean>(false)
  const [showingRequestDeletionConfirmation, setShowingRequestDeletionConfirmation] =
    useState<boolean>(false)
  const [alert, setAlert] = useState<undefined | AlertColor>()

  useEffect(() => logEvent(analytics, "page_my_account", { uid: profile?.uid }), [])

  useEffect(() => {
    if (profile?.dataConsent === undefined) setShowingDataCollection(true)
  }, [profile])

  return profile ? (
    <div className="pt-[10px]">
      <PillButtonArray
        pillArray={[
          { title: "User", onClick: () => setSelectedTab("User") },
          { title: "Security", onClick: () => setSelectedTab("Security") },
        ]}
        selected={selectedTab}
        setSelected={setSelectedTab}
        extraMarginBottom={true}
      />
      <CVCardBody roundedTop={true}>
        {selectedTab === "Security" ? (
          <ResetPassword showingCurrent={true} />
        ) : (
          <UserCard userData={profile} section="Account Management" />
        )}
      </CVCardBody>
      <CVCardFooter>
        <ButtonRow
          buttonArray={
            selectedTab === "Security"
              ? [
                  {
                    title: ls.getText("Reset Password"),
                    onClick: () => resetPW().then((res) => setSuccess(res)),
                    variant: "contained",
                  },
                  {
                    title: ls.getText("request_deletion"),
                    color: "error",
                    onClick: () => setShowingRequestDeletionConfirmation(true),
                    variant: "outlined",
                  },
                ]
              : [
                  {
                    title: "Sign Out",
                    onClick: () => signOut(),
                    variant: "contained",
                  },
                ]
          }
        />
      </CVCardFooter>
      <BasicModal open={!!success} setClosed={() => setSuccess("")}>
        {success && <Alert severity="success">{success}</Alert>}
      </BasicModal>
      <BasicModal
        open={showingDataCollection}
        setClosed={() => (profile.dataConsent ? setShowingDataCollection(false) : {})}
      >
        <DataCollection setShowing={setShowingDataCollection} />
      </BasicModal>
      {showingRequestDeletionConfirmation && (
        <ConfirmRequestDeletion
          showing={showingRequestDeletionConfirmation}
          setShowing={setShowingRequestDeletionConfirmation}
          setAlert={setAlert}
        />
      )}
      {alert && (
        <BasicModal open={alert ? true : false} setClosed={() => setAlert(undefined)}>
          <Alert severity={alert} onClose={() => setAlert(undefined)}>
            {ls.getText(alert === "error" ? "Error Sending Your Request" : "Request Sent")}
          </Alert>
        </BasicModal>
      )}
    </div>
  ) : (
    <Spinner />
  )
}

export default MyAccount
