import { useEffect, useState } from "react"
import { Alert, AlertTitle } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { useCurrentHandContext } from "@context/remoteAssistProviders"
import CVCardBody from "@ui/sections/CVCardBody"
import { stringVersionToInt } from "@util/commonFunctions/version"
import CVSubtitle from "@ui/text/CVSubtitle"
import DigitPositionIndicator from "./DigitPositionIndicator"
import { Config } from "@covvi/common-functions"

const DigitData = ({
  sendCommand,
  handConfig,
}: {
  sendCommand: (command: string) => void
  handConfig: Config | undefined
}) => {
  const { digitPositions, emitData } = useCurrentHandContext()
  const [showingFirmwareWarning, setShowingFirmwareWarning] = useState<boolean>(false)

  useEffect(() => {
    emitData({ type: "streamStop", data: "digits" })
    setTimeout(() => emitData({ type: "stream", data: "digits" }), 250)
    return () => emitData({ type: "streamStop", data: "digits" })
  }, [])

  useEffect(() => {
    if (handConfig) {
      if (stringVersionToInt(handConfig?.firmwareVersion || "0") < stringVersionToInt("5.1.25")) {
        setShowingFirmwareWarning(true)
      }
    }
  }, [handConfig])

  return digitPositions && handConfig ? (
    <>
      {showingFirmwareWarning && (
        <Alert sx={{ borderRadius: 3, marginBottom: "16px" }} severity="warning">
          <AlertTitle>{ls.getText("warning")}</AlertTitle>
          {ls.getText("firmware_digit_drive_warning")}
        </Alert>
      )}
      <CVCardBody roundedBottom={true} roundedTop={true}>
        <div className="m-4">
          <CVSubtitle
            text={ls.getText("Positions")}
            helperText={ls.getText("digit_positions_help")}
          />
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6">
            {[
              ["Thumb", "01"],
              ["Index", "02"],
              ["Middle", "04"],
              ["Ring", "08"],
              ["Little", "10"],
              ["Rotate", "20"],
            ].map(([title, index], i) => (
              <DigitPositionIndicator
                key={i}
                title={title}
                sendCommand={sendCommand}
                configHex={handConfig.configHex}
                index={index}
              />
            ))}
          </div>
        </div>
      </CVCardBody>

      <div className="mb-6" />

      <CVCardBody roundedBottom={true} roundedTop={true}>
        <div className="flex flex-col m-4">
          <CVSubtitle
            text={ls.getText("Voltages")}
            helperText={ls.getText("digit_voltages_help")}
          />
          <div className="flex flex-col w-full 2xl:w-1/2 font-medium">
            <span className="">
              {ls.getText("Battery")} - {digitPositions.batteryVoltage}mV
            </span>
            <span className="">
              {ls.getText("input_a")} - {digitPositions.channelAOuputVoltage}mV
            </span>
            <span className="">
              {ls.getText("input_b")} - {digitPositions.channelBOuputVoltage}mV
            </span>
          </div>
        </div>
      </CVCardBody>
    </>
  ) : (
    <div className="p-4">{ls.getText("Waiting to connect to hand")}</div>
  )
}

export default DigitData
