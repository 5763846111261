import { useEffect, useRef, useState } from "react"

import { useCurrentHandContext } from "@context/remoteAssistProviders"
import { getStart, getVal, replaceAt, toHex } from "@covvi/common-functions"
import { languageSelector as ls } from "@covvi/language-selector"

import InputLevelBar from "@components/input graph/InputLevelBar"
import ThresholdBar from "@components/input graph/ThresholdBar"
import CVSubtitle from "@ui/text/CVSubtitle"

interface Props {
  configHex: string | undefined
  disableElectrodeHandles: boolean
}

const ElectrodeData = ({ configHex, disableElectrodeHandles }: Props) => {
  const { inputA, inputB, emitData } = useCurrentHandContext()
  const [onA, setOnA] = useState<number>(0)
  const [coconA, setCoconA] = useState<number>(0)
  const [maxA, setMaxA] = useState<number>(5000)
  const [onB, setOnB] = useState<number>(0)
  const [coconB, setCoconB] = useState<number>(0)
  const [maxB, setMaxB] = useState<number>(5000)
  const [coconActive, setCoconActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const updateTimer = useRef<NodeJS.Timeout>()
  const configString = useRef<string>()

  useEffect(() => {
    emitData({ type: "streamStop", data: "electrode" })
    return () => emitData({ type: "streamStop", data: "electrode" })
  }, [])

  const setElectrodeData = () => {
    setTimeout(() => emitData({ type: "stream", data: "electrode" }), 250)
    updateTimer.current && clearTimeout(updateTimer.current)
    configString.current = configHex
    setOnA(getVal("input_a_on_threshold", configHex).int)
    setCoconA(getVal("input_a_cocon_threshold", configHex).int)
    setMaxA(getVal("input_a_max_threshold", configHex).int)
    setOnB(getVal("input_b_on_threshold", configHex).int)
    setCoconB(getVal("input_b_cocon_threshold", configHex).int)
    setMaxB(getVal("input_b_max_threshold", configHex).int)
    setCoconActive(getVal("cocontraction", configHex).hex !== "00")
    setIsLoading(false)
  }

  useEffect(() => setElectrodeData(), [configHex])

  const onMove = (values: number | number[], input: "a" | "b") => {
    if (typeof values === "number" || !configHex || !configString.current) return
    if (values[0] + 200 > values[1] || (coconActive && values[1] + 200 > values[2])) {
      return
    } else {
      if (input === "a") {
        setOnA(values[0])
        coconActive && setCoconA(values[1])
        setMaxA(values[coconActive ? 2 : 1])
      } else if (input === "b") {
        setOnB(values[0])
        coconActive && setCoconB(values[1])
        setMaxB(values[coconActive ? 2 : 1])
      }
      const updateParam = (parameter: string, numVal: number) => {
        configString.current = replaceAt(
          configString.current!,
          getStart(parameter, configHex),
          toHex(numVal, 2)
        )
      }
      updateParam(`input_${input}_on_threshold`, values[0])
      if (coconActive) {
        updateParam(`input_${input}_cocon_threshold`, values[1])
        updateParam(`input_${input}_max_threshold`, values[2])
      } else {
        updateParam(`input_${input}_max_threshold`, values[1])
      }
    }
  }

  const onRelease = () => {
    if (configString.current !== configHex) {
      setIsLoading(true)
      updateTimer.current = setTimeout(() => setElectrodeData(), 8000) // reset electrode data if fails
      emitData({ type: "streamStop", data: "electrode" })
      setTimeout(() => emitData({ type: "config", data: configString.current! }), 250)
    }
  }

  return (
    <div className="flex flex-col p-8">
      <CVSubtitle text={ls.getText("input_a")} helperText={ls.getText("electrode_data_help")} />
      <ThresholdBar
        onVal={onA}
        coconVal={coconActive && coconA}
        maxVal={maxA}
        maxInput={getVal("input_a_max_input", configHex).int}
        disabled={disableElectrodeHandles || isLoading}
        onMove={(...args) => onMove(...args, "a")}
        onRelease={() => onRelease()}
      />
      <InputLevelBar
        val={inputA}
        maxInput={getVal("input_a_max_input", configHex).int}
        className="bg-purple-500 rounded-l-md opacity-70"
      />
      <InputLevelBar
        val={inputB}
        maxInput={getVal("input_b_max_input", configHex).int}
        className="bg-blue-500 rounded-l-md opacity-70"
      />
      <ThresholdBar
        onVal={onB}
        coconVal={coconActive && coconB}
        maxVal={maxB}
        maxInput={getVal("input_b_max_input", configHex).int}
        disabled={disableElectrodeHandles || isLoading}
        onMove={(...args) => onMove(...args, "b")}
        onRelease={() => onRelease()}
      />
      <div className="h-[12px]"></div>
      <CVSubtitle text={ls.getText("input_b")} />
    </div>
  )
}
export default ElectrodeData
