import { useEffect, useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import { NonLinearSlider } from "@ui/inputs/NonLinearSlider"
import { ToggleGroup } from "@ui/inputs/ToggleGroup"
import { SecondaryTabArray } from "@ui/sections/SecondaryTabArray"
import { GeneralGraphOptions } from "@typesFolder/types"

type Selected =
  | "general"
  | "hand_owner"
  | "hand_type"
  | "config_version_name"
  | "usage"
  | "connections"
  | "scaling"
  | "time_difference"
  | "by_date"

interface OptionGroup {
  title: Selected
  options?: { title: string; checked: boolean; setChecked: () => void }[]
  slider?: { values: [number, number]; setValues: (values: [number, number]) => void }
  summary?: string
}

const AllGeneralOptions = ({
  graphOptions,
  setGraphOptions,
  optionType,
}: {
  graphOptions: GeneralGraphOptions
  setGraphOptions: React.Dispatch<React.SetStateAction<GeneralGraphOptions>>
  optionType: "Stats" | "Errors"
}) => {
  const [selected, setSelected] = useState<Selected>("general")
  const [option, setOption] = useState<OptionGroup>()
  useEffect(
    () => setOption(optionGroups.filter((option) => option.title === selected)[0]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphOptions, selected]
  )

  const nonZero = (current: GeneralGraphOptions) => {
    return current.connections[0] > 0
      ? current.connections
      : ([1, current.connections[1]] as [number, number])
  }

  const optionGroups: OptionGroup[] = [
    {
      title: "general",
      options: [
        {
          title: "use_median",
          checked: graphOptions.showingMedian,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingMedian: !graphOptions.showingMedian,
            })),
        },
        {
          title: "use_mean",
          checked: !graphOptions.showingMedian,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingMedian: !graphOptions.showingMedian,
            })),
        },
      ],
    },
    {
      title: "hand_owner",
      summary: "hand_type_summary",
      options: [
        {
          title: "r_and_d",
          checked: graphOptions.showingRandD,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingRandD: !graphOptions.showingRandD,
            })),
        },
        {
          title: "loaner",
          checked: graphOptions.showingLoaner,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingLoaner: !graphOptions.showingLoaner,
            })),
        },
        {
          title: "demo",
          checked: graphOptions.showingDemo,
          setChecked: () =>
            setGraphOptions((current) => ({ ...current, showingDemo: !graphOptions.showingDemo })),
        },
        {
          title: "customer",
          checked: graphOptions.showingCustomer,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingCustomer: !graphOptions.showingCustomer,
            })),
        },
      ],
    },
    {
      title: "hand_type",
      summary: "hand_type_summary",
      options: [
        {
          title: ls.getText("Left"),
          checked: graphOptions.showingLeft,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingLeft: !graphOptions.showingLeft,
            })),
        },
        {
          title: ls.getText("Right"),
          checked: graphOptions.showingRight,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingRight: !graphOptions.showingRight,
            })),
        },
        {
          title: ls.getText("small"),
          checked: graphOptions.showingSmall,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingSmall: !graphOptions.showingSmall,
            })),
        },
        {
          title: ls.getText("medium"),
          checked: graphOptions.showingMedium,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingMedium: !graphOptions.showingMedium,
            })),
        },
        {
          title: ls.getText("large"),
          checked: graphOptions.showingLarge,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showingLarge: !graphOptions.showingLarge,
            })),
        },
      ],
    },
    {
      title: "config_version_name",
      summary: "",
      options: [
        {
          title: "105",
          checked: graphOptions.showing105,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showing105: !graphOptions.showing105,
            })),
        },
        {
          title: "106",
          checked: graphOptions.showing106,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showing106: !graphOptions.showing106,
            })),
        },
        {
          title: "107",
          checked: graphOptions.showing107,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              showing107: !graphOptions.showing107,
            })),
        },
      ],
    },
    {
      title: "usage",
      summary: optionType === "Stats" ? "powered_on_summary" : "abs_time_summary",
      slider: {
        values: graphOptions.usage,
        setValues: (values: [number, number]) =>
          setGraphOptions((current) => ({ ...current, usage: values })),
      },
    },
    {
      title: "connections",
      summary: "connections_summary",
      slider: {
        values: graphOptions.connections,
        setValues: (values: [number, number]) =>
          setGraphOptions((current) => ({
            ...current,
            timePeriod: values[0] === 0 ? "allTime" : graphOptions.timePeriod,
            snapshotTimeDifference: values[0] === 0 ? "any" : graphOptions.snapshotTimeDifference,
            connections: values,
          })),
      },
    },
    {
      title: "scaling",
      summary: "scaling_summary",
      options: [
        {
          title: "all_time",
          checked: graphOptions.timePeriod === "allTime",
          setChecked: () => setGraphOptions((current) => ({ ...current, timePeriod: "allTime" })),
        },
        {
          title: "daily",
          checked: graphOptions.timePeriod === 86400000,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              connections: nonZero(current),
              timePeriod: 86400000,
            })),
        },
        {
          title: "weekly",
          checked: graphOptions.timePeriod === 604800000,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              connections: nonZero(current),
              timePeriod: 604800000,
            })),
        },
        {
          title: "monthly",
          checked: graphOptions.timePeriod === 2630000000,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              connections: nonZero(current),
              timePeriod: 2630000000,
            })),
        },
      ],
    },
  ]

  if (optionType === "Stats" && graphOptions.showingPassiveUsage !== undefined) {
    optionGroups[0].options?.push({
      title: "passive_usage",
      checked: graphOptions.showingPassiveUsage,
      setChecked: () =>
        setGraphOptions((current) => ({
          ...current,
          showingPassiveUsage: !graphOptions.showingPassiveUsage,
        })),
    })
    optionGroups.push({
      title: "time_difference",
      summary: "time_difference_summary",
      options: [
        {
          title: "any_difference",
          checked: graphOptions.snapshotTimeDifference === "any",
          setChecked: () =>
            setGraphOptions((current) => ({ ...current, snapshotTimeDifference: "any" })),
        },
        {
          title: "1_day",
          checked: graphOptions.snapshotTimeDifference === 86400000,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              connections: nonZero(current),
              snapshotTimeDifference: 86400000,
            })),
        },
        {
          title: "1_week",
          checked: graphOptions.snapshotTimeDifference === 604800000,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              connections: nonZero(current),
              snapshotTimeDifference: 604800000,
            })),
        },
        {
          title: "1_month",
          checked: graphOptions.snapshotTimeDifference === 2630000000,
          setChecked: () =>
            setGraphOptions((current) => ({
              ...current,
              connections: nonZero(current),
              snapshotTimeDifference: 2630000000,
            })),
        },
      ],
    })
  } else if (optionType === "Errors" && graphOptions.by_date) {
    optionGroups.push({
      title: "by_date",
      summary: "by_date_summary",
      slider: {
        values: graphOptions.by_date,
        setValues: (values: [number, number]) =>
          setGraphOptions((current) => ({ ...current, by_date: values })),
      },
    })
  }

  return (
    <div className="flex flex-col w-full md:w-1/2 lg:flex-1 py-2 bg-white rounded-xl px-10 border-[#B8B8B8] border">
      <SecondaryTabArray
        buttonArray={optionGroups}
        selected={selected}
        setSelected={(val) => setSelected(val as Selected)}
        isWithinCard={true}
      />
      <div className="pt-2">
        {option?.summary && <h1>{ls.getText(option.summary)}</h1>}
        {option?.options && <ToggleGroup options={option.options} />}
        {option?.slider && (
          <div className="pt-2">
            <NonLinearSlider
              title={option.title}
              values={option.slider!.values}
              setValues={option.slider!.setValues}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default AllGeneralOptions
