import { getDownloadURL, StorageReference } from "firebase/storage"
import { getStorage, ref, listAll } from "firebase/storage"
import { UserGrip } from "@covvi/common-functions"

export async function getAvailableUserGrips(): Promise<UserGrip[]> {
  const storage = getStorage()
  const listRef = ref(storage, "UserDefinedGrips")
  const userGripsRefs = await listAll(listRef)
    .then((res) => res.items)
    .catch((error) => {
      throw new Error(error)
    })
  return Promise.all(userGripsRefs.map((ref) => getUserGrip(ref)))
    .then((userGrips) => userGrips as UserGrip[])
    .catch((error) => {
      throw new Error(error)
    })
}

export async function getUserGrip(reference: StorageReference): Promise<UserGrip> {
  return await getDownloadURL(reference)
    .then((url) => fetch(url))
    .then((response) => response.json())
    .then((res) => {
      const data = res.Covvi_Grip as UserGrip
      const userGrip = { ...data, Name: data.Name.replace(/[^0-9a-z\s]/gi, "").trim() } // Some grips can have non alphanumeric characters...
      return userGrip as UserGrip
    })
    .catch((error) => {
      throw new Error(error)
    })
}
