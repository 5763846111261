import React, { createContext, useContext, useState, useEffect, useCallback, Dispatch } from "react"
import { useNavigate } from "react-router-dom"
import validator from "validator"

import { languageSelector as ls } from "@covvi/language-selector"
import { User, onAuthStateChanged } from "firebase/auth"
import {
  getProfile,
  login,
  performPasswordReset,
  requestResetPasswordEmail,
  resetPassword,
} from "@util/firebase/authFunctions"
import { auth } from "@util/firebase/firebase"
import { ResetPWPromise, ResetParams } from "@typesFolder/types"
import { ProfileData, validatePassword } from "@covvi/common-functions"

type AuthUser = User | null
type Profile = ProfileData | null | undefined
type AuthError = string | undefined
type ContextState = {
  user: AuthUser
  profile: Profile
  authError?: string
  authLoading: boolean
  signIn: (email: string, password: string) => void
  signOut: () => void
  resetPW: ResetPWPromise
  sendResetPW: (email: string) => void
  resetParams: ResetParams
  setResetParams: Dispatch<React.SetStateAction<ResetParams>>
  resetResult: { loading: boolean; success: boolean; error: string }
}

const AuthContext = createContext<ContextState | undefined>(undefined)

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<AuthUser>(null)
  const [profile, setProfile] = useState<Profile>()
  const [authError, setAuthError] = useState<AuthError>()
  const [authLoading, setAuthLoading] = useState(true)
  const [resetParams, setResetParams] = useState<ResetParams>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  })
  const [resetResult, setResetResult] = useState({ loading: false, success: false, error: "" })

  const signOut = useCallback(() => {
    auth.signOut()
    setProfile(null)
    setUser(null)
    navigate("/login", { replace: true })
  }, [navigate])

  const unsubscribe = useCallback(() => {
    onAuthStateChanged(auth, (newUser) => {
      setAuthLoading(true)
      setUser(newUser)
      newUser
        ? getProfile(newUser.uid)
            .then((profile) => {
              setProfile(profile)
              setAuthLoading(false)
            })
            .catch((e) => {
              setAuthError(e)
              setAuthLoading(false)
              signOut()
            })
        : setAuthLoading(false)
    })
  }, [])

  useEffect(() => {
    unsubscribe()
    return unsubscribe
  }, [unsubscribe])

  const signIn = (email: string, password: string) => {
    if (!validator.isEmail(email)) {
      setAuthError(ls.getText("Please enter a valid email address"))
    } else if (password.length < 8) {
      setAuthError(ls.getText("pass_8_20"))
    } else {
      login(email, password)
        .then(() => {
          setResetParams({ newPassword: "", confirmNewPassword: "", currentPassword: "" })
          navigate("/", { replace: true })
        })
        .catch((error) => {
          setAuthError(ls.getText("password_incorrect"))
        })
    }
  }

  const resetPW = async () => {
    return new Promise<string>(async (resolve, reject) => {
      const error = validatePassword(resetParams.newPassword, resetParams.confirmNewPassword)
      if (error) {
        setResetResult((resetResult) => {
          return { ...resetResult, error }
        })
        reject("failed")
        return
      } else {
        setResetResult((resetResult) => {
          return { ...resetResult, loading: true }
        })
      }
      if (resetParams.email && resetParams.oobCode) {
        await performPasswordReset(resetParams.oobCode!, resetParams.newPassword, resetParams.email)
          .then((res) => {
            setResetResult({ loading: false, success: true, error: "" })
            resolve("success")
          })
          .catch((error) => {
            setResetResult({ loading: false, success: false, error: ls.getText("reset_problem") })
            reject(ls.getText("reset_problem"))
          })
      } else {
        if (!user) {
          reject("Not logged in")
        } else {
          await resetPassword({
            user: user!,
            newPassword: resetParams.newPassword,
            currentPassword: resetParams.currentPassword!,
            clearTemp: profile?.completed ? false : true,
          })
            .then(() => {
              profile && setProfile({ ...profile, completed: true })
              setResetParams({ newPassword: "", confirmNewPassword: "", currentPassword: "" })
              setResetResult({ loading: false, success: true, error: "" })
              resolve("Password Changed")
            })
            .catch((e) => {
              setResetResult({ loading: false, success: false, error: ls.getText("reset_problem") })
              reject(e)
            })
        }
      }
    })
  }

  const sendResetPW = (email: string) => {
    requestResetPasswordEmail(email)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        authError,
        authLoading,
        signIn,
        signOut,
        resetPW,
        sendResetPW,
        resetResult,
        resetParams,
        setResetParams,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return {
    user: context.user,
    profile: context.profile,
    authError: context.authError,
    authLoading: context.authLoading,
    signIn: context.signIn,
    signOut: context.signOut,
    resetPW: context.resetPW,
    sendResetPW: context.sendResetPW,
  }
}

const useReset = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useReset must be used within an AuthProvider")
  }
  return {
    resetPW: context.resetPW,
    resetParams: context.resetParams,
    setResetParams: context.setResetParams,
    resetResult: context.resetResult,
    signIn: context.signIn,
  }
}

export { AuthProvider, useAuth, useReset }
