import { useState } from "react"
import { TextField } from "@mui/material"

import { useNavigate } from "react-router-dom"
import { languageSelector as ls } from "@covvi/language-selector"
import { deleteUser } from "@util/firebase/userFunctions"

import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import BasicModal from "@ui/modals/BasicModal"
import CVCloseModal from "@ui/modals/CVCloseModal"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { ProfileData } from "@covvi/common-functions"

interface Props {
  modalOpen: boolean
  setModalOpen: Function
  user: ProfileData
  setSuccess: Function
  deleteTargetEmail: string
}

const DeleteUserConfirmationModal = ({
  modalOpen,
  setModalOpen,
  user,
  setSuccess,
  deleteTargetEmail,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [confirmEmail, setConfirmEmail] = useState<string>("")
  const navigate = useNavigate()

  return (
    <>
      <BasicModal open={modalOpen} setClosed={() => setModalOpen(false)}>
        <CVCardHeader>
          <CVCardTitle title={ls.getText("Delete User")} />
          <CVCloseModal setShowingModal={setModalOpen} />
        </CVCardHeader>
        <CVCardBody>
          <div className="p-[30px] flex flex-col flex-1 space-y-2">
            <p>{`${ls.getText("delete_user_confirm")} ${deleteTargetEmail}?`}</p>
            <p>{ls.getText("reenter_email")}</p>
            <TextField
              variant="outlined"
              size="small"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            />
          </div>
        </CVCardBody>
        <CVCardFooter>
          <>
            <ButtonRow
              buttonArray={[
                {
                  title: `${ls.getText("Delete")} ${ls.getText(user.role)}`,
                  variant: "contained",
                  color: "error",
                  loading: loading,
                  disabled: deleteTargetEmail.toLowerCase() !== confirmEmail.toLowerCase(),
                  onClick: () => {
                    setLoading(true)
                    deleteUser(user).then(() => {
                      setSuccess({
                        text: `${ls.getText(user.role)} ${ls.getText("Deleted")}`,
                        severity: "success",
                      })
                      setTimeout(() => {
                        setLoading(false)
                        setModalOpen(false)
                        navigate("/users")
                      }, 1000)
                    })
                  },
                },
                {
                  variant: "outlined",
                  onClick: () => setModalOpen(false),
                  title: ls.getText("Cancel"),
                },
              ]}
            />
          </>
        </CVCardFooter>
      </BasicModal>
    </>
  )
}

export default DeleteUserConfirmationModal
