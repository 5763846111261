import { languageSelector as ls } from "@covvi/language-selector"

interface Props {
  title: string
  value: string | undefined
  whiteBackground?: boolean
}

const CVKeyValueInfoCard = ({ title, value, whiteBackground }: Props) => {
  return (
    <div
      className={`rounded-[10px] border-[#B8B8B8] border p-5 flex flex-1 flex-col space-y-[15px] sm:whitespace-nowrap overflow-x-auto ${
        whiteBackground ? "bg-white" : ""
      }`}
    >
      <h3 className="font-semibold text-lg text-[#464646]">{title}</h3>
      <h4 className="text-base text-[#464646]">{value || ls.getText("Unknown")}</h4>
    </div>
  )
}

export default CVKeyValueInfoCard
