import { Dispatch } from "react"
import { collection, doc, getDoc, getDocs, onSnapshot, updateDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { getDownloadURL, ref } from "firebase/storage"
import { firestore, functions, storage } from "./firebase"
import { TicketContent, TicketWithStatus, SupportMessage } from "@typesFolder/supportTypes"

export const getAllSupportTickets = (role: string) => {
  return new Promise<TicketContent[]>((resolve, reject) => {
    if (!["Admin", "Customer Service Team Member"].includes(role)) {
      reject("Unauthorised")
    }
    getDocs(collection(firestore, "SupportTickets"))
      .then((querySnapshot) => {
        let tickets: TicketContent[] = []
        querySnapshot.forEach((ticket) => {
          const ticketData = ticket.data()
          const ticketId = ticket.id
          if (!ticketData.serialNumber) {
            ticketData.serialNumber = ""
          }
          tickets.push({ id: ticketId, ...ticketData } as TicketContent)
        })

        resolve(tickets)
      })
      .catch(reject)
  })
}

export const getTicketDetails = (id: string, role: string) => {
  return new Promise<TicketWithStatus>((resolve, reject) => {
    if (!["Admin", "Customer Service Team Member"].includes(role)) {
      reject("Unauthorised")
    }
    getDoc(doc(firestore, "SupportTickets", id))
      .then((snap) => resolve(snap.data() as TicketWithStatus))
      .catch(reject)
  })
}

export const supportMessagesListener = (
  ticketId: string,
  setMessages: Dispatch<React.SetStateAction<SupportMessage[] | undefined>>
) => {
  const sort = (a: SupportMessage, b: SupportMessage) => {
    if (a.date < b.date) {
      return -1
    } else if (a.date > b.date) {
      return 1
    } else {
      return 0
    }
  }

  return onSnapshot(collection(firestore, `SupportTickets/${ticketId}/messages`), (doc) => {
    let messages: SupportMessage[] = []

    doc.docs.forEach((ticket) => {
      const messageData = ticket.data()
      const messageId = ticket.id
      messages.push({ id: messageId, ...messageData } as SupportMessage)
    })

    if (messages.length !== 0) {
      messages = messages.sort((a, b) => {
        return sort(a, b)
      })
    }

    setMessages(messages)
  })
}

export const sendSupportEmail = ({
  from_email,
  name,
  ticketId,
  email_content,
  attachments,
  role,
}: {
  from_email: string
  name: string
  ticketId: string
  email_content: string[]
  attachments: any
  role: string
}) => {
  const supportEmailFromPortal = httpsCallable(functions, "supportEmailFromPortal")
  return new Promise((resolve, reject) => {
    if (!["Admin", "Customer Service Team Member"].includes(role)) {
      reject("Unauthorised")
    }
    supportEmailFromPortal({
      from_email,
      name,
      ticketId,
      email_content,
      attachments,
    })
      .then(() => {
        resolve(true)
      })
      .catch(reject)
  })
}

export const getSupportStructure = () => {
  return new Promise<any>((resolve, reject) => {
    getDownloadURL(ref(storage, "structure.json"))
      .then((url) => {
        fetch(url)
          .then((response) => {
            response
              .json()
              .then((data) => {
                resolve(data)
              })
              .catch((error) => reject(error))
          })
          .catch((error) => reject(error))
      })
      .catch(reject)
  })
}

export const generateTicketFromPortal = ({
  ticket,
  role,
}: {
  ticket: TicketContent
  role: string
}) => {
  const addSupportTicketFromPortal = httpsCallable(functions, "addSupportTicketFromPortal")
  return new Promise<{ id: string }>((resolve, reject) => {
    if (!["Admin", "Customer Service Team Member"].includes(role)) {
      reject("Unauthorised")
    }
    addSupportTicketFromPortal({ ticketData: ticket })
      .then((response) => {
        resolve(response.data as { id: string })
      })
      .catch(reject)
  })
}

export const closeReopenTicketMail = ({
  ticketId,
  ticketStatus,
  mailStatus,
  role,
}: {
  ticketId: string
  ticketStatus: string
  mailStatus: string
  role: string
}) => {
  const closeReopenTicket = httpsCallable(functions, "closeReopenTicket")
  return new Promise<string>(async (resolve, reject) => {
    if (!["Admin", "Customer Service Team Member"].includes(role)) {
      reject("Unauthorised")
    }
    await closeReopenTicket({ ticketId: ticketId, ticketStatus: mailStatus })
      .then((response) => {
        return response
      })
      .catch(reject)
    await updateDoc(doc(firestore, "SupportTickets", ticketId), {
      ticketStatus: ticketStatus,
    })
      .then(() => {
        resolve(ticketId)
      })
      .catch(reject)
  })
}
