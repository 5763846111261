import { useEffect, useState } from "react"
import { SecondaryTabArray } from "@ui/sections/SecondaryTabArray"
import Graph from "@ui/graphs/Graph"
import Spinner from "@ui/spinners/Spinner"
import CVCardBody from "@ui/sections/CVCardBody"
import { useAuth } from "@context/AuthContext"
import { logEvent } from "firebase/analytics"
import { analytics } from "@util/firebase/firebase"

const StatsGeneral = ({
  sections,
  title,
}: {
  sections: { title: string; data: { [key: string]: number } }[] | undefined
  title: string
}) => {
  const { profile } = useAuth()
  const [selected, setSelected] = useState<string>("")

  useEffect(() => {
    logEvent(analytics, "page_stats", {
      uid: profile?.uid,
      subPage: title,
    })
  }, [])

  useEffect(() => {
    sections && setSelected(sections[0].title)
    return () => setSelected("")
  }, [sections])

  return sections ? (
    <div className="flex flex-col flex-1">
      <SecondaryTabArray
        buttonArray={sections}
        selected={selected}
        setSelected={setSelected}
        isWithinCard={false}
      />
      <div className="flex flex-col flex-1 w-full mt-4">
        <CVCardBody roundedTop={true} roundedBottom={true}>
          <Graph
            graphData={sections.filter((section) => section.title === selected)[0]}
            pageTitle={title}
          />
        </CVCardBody>
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default StatsGeneral
