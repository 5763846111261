import { useEffect, useState } from "react"
import { getVal, schemaFromConfigHex, UserGrips } from "@covvi/common-functions"
import { useAuth } from "@context/AuthContext"
import { sectionSetter } from "@util/configFunctions/sectionSetter"
import { ConfigSectionInterface } from "@typesFolder/types"

const InputSettings = ({
  configHex,
  updateParameter,
  canEdit,
  userGrips,
  isLoading,
}: ConfigSectionInterface) => {
  const { profile } = useAuth()
  const [sections, setSections] = useState<JSX.Element[]>([])
  const [inputA, setInputA] = useState<JSX.Element[]>([])
  const [inputB, setInputB] = useState<JSX.Element[]>([])

  useEffect(() => {
    const disabledOverrides: string[] = []
    profile &&
      profile.role !== "Admin" &&
      getVal("config_version", configHex).int < 106 &&
      getVal("auto_grip", configHex).hex === "00" &&
      disabledOverrides.push("auto_grip")

    const sections = (partial: string | undefined, partials: string[]) =>
      sectionSetter(
        configHex,
        schemaFromConfigHex(configHex).input_settings,
        { wholeNames: ["cocontraction_time"], partial },
        { canEdit: isLoading ? false : canEdit, overrides: disabledOverrides, partials },
        updateParameter
      )

    setSections(sections(undefined, ["hysteresis", "buffer", "off_threshold"]))
    setInputA(sections("input_b", ["hysteresis", "buffer", "off_threshold"]))
    setInputB(sections("input_a", ["hysteresis", "buffer", "off_threshold"]))
  }, [configHex, isLoading])

  return (
    <>
      <div className="lg:hidden grid gap-x-10  md:grid-cols-1 ">{sections}</div>
      <div className="hidden lg:grid grid-cols-2 gap-x-10">
        <div>{inputA}</div>
        <div>{inputB}</div>
      </div>
    </>
  )
}

export default InputSettings
