import { useEffect, useState } from "react"
import { TextField, MenuItem } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"

import SelectInput from "@ui/inputs/ConfigInputs/SelectInput"
import {
  getVal,
  GripMediaMap,
  replaceAt,
  schemaFromConfigHex,
  UpdateUserGrip,
  UserGrip,
  UserGrips,
} from "@covvi/common-functions"
import { updateGrip } from "@util/configFunctions/updateGrip"
import { images } from "@assets/Grips/images"
import { getAvailableUserGrips } from "@util/firebase/handFunctions/userGripFunctions"

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"

type ParamArr = { value: string; displayValue: string }[]
type GripTable = "a" | "b" | "c" | "d" | "MapToGrip"

const GripTables = ({
  configHex,
  setConfigHex,
  canEdit,
  userGrips,
  addUserGrip,
  editUserGrips,
  isLoading,
}: {
  configHex: string
  setConfigHex: undefined | ((configHex: string) => void)
  canEdit: boolean
  userGrips: UserGrips | undefined
  addUserGrip: (grip: UpdateUserGrip) => void
  editUserGrips: (grip: UserGrip, updateCommands: string[]) => void
  isLoading?: boolean
}) => {
  const [selected, setSelected] = useState<GripTable>("a")
  const [tableGrips, setTableGrips] = useState<ParamArr>([])
  const [gripOtions, setGripOptions] = useState<ParamArr>([])
  const [availableUserGrips, setAvailableUserGrips] = useState<UserGrip[]>([])

  useEffect(() => {
    getAvailableUserGrips().then((grips) => setAvailableUserGrips(grips))
  }, [])

  useEffect(() => {
    setGripOptions(
      schemaFromConfigHex(configHex)
        .grip_patterns.map((option, i) => {
          const dV =
            userGrips && option.name.startsWith("user_grip")
              ? ls.getText("user_grips") + " - " + ls.getText(userGrips[i - 15])
              : option.name

          return {
            value: option.value,
            displayValue: dV,
          }
        })
        .filter(
          (option) =>
            option.displayValue !== ls.getText("user_grips") + " - " + ls.getText("No Grip")
        )
    )
  }, [configHex])

  useEffect(
    () =>
      setTableGrips(
        [1, 2, 3, 4, 5, 6].map((i) => ({
          displayValue: `table_${selected}_grip_${i}`,
          value: getVal(`table_${selected}_grip_${i}`, configHex).hex,
        }))
      ),
    [selected, configHex]
  )

  const updateParam = (paramName: string, displayValue: string) => {
    const command = updateGrip(
      configHex,
      userGrips,
      paramName.split("_")[1] as GripTable,
      parseInt(paramName.split("_")[3]) - 1,
      displayValue,
      availableUserGrips,
      addUserGrip,
      editUserGrips
    )

    setConfigHex &&
      command &&
      setConfigHex(
        replaceAt(configHex, parseInt(command.substring(0, 2), 16), command.substring(2))
      )
  }

  return (
    <div className="flex flex-col justify-between py-2">
      <TextField
        className="basis-full"
        variant="outlined"
        label={ls.getText("Select Grip Table")}
        value={selected}
        select
        onChange={(e) => setSelected(e.target.value as GripTable)}
        required
        disabled={isLoading}
      >
        {["a", "b", "c", "d"].map((table, i) => (
          <MenuItem key={i} value={table}>
            {ls.getText(`table_${table}`)}
          </MenuItem>
        ))}
      </TextField>

      <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-x-10 pt-10 mx-auto">
        {tableGrips.map((param, i) => {
          return (
            <div className="flex flex-col max-w-[250px] " key={i}>
              {param.value === "00" ? (
                <div className="flex-1 bg-veryDarkCovviGrey my-2 rounded-md">
                  <div className="flex-1 m-auto">
                    <QuestionMarkCircleIcon className="flex-1 text-white" />
                  </div>
                </div>
              ) : (
                <div className="py-2 rounded-md">
                  <video
                    className={"rounded-md"}
                    src={
                      parseInt(param.value, 16) >= 15
                        ? availableUserGrips.find(
                            (grip) =>
                              userGrips && grip.Name === userGrips[parseInt(param.value, 16) - 15]
                          )?.Video_URL
                        : images[param.value as keyof GripMediaMap].video
                    }
                    autoPlay={true}
                    loop={true}
                  />
                </div>
              )}

              <SelectInput
                title={param.displayValue}
                currentValue={param.value}
                options={gripOtions}
                setValue={(paramName: string, value: string) => updateParam(paramName, value)}
                canEdit={isLoading ? false : canEdit}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GripTables
