import { GeneralParam, getVal, IntegerParam, OptionParamOption } from "@covvi/common-functions"
import { languageSelector as ls } from "@covvi/language-selector"
import NumberInput from "@ui/inputs/ConfigInputs/NumberInput"
import SelectInput from "@ui/inputs/ConfigInputs/SelectInput"
import TextInput from "@ui/inputs/ConfigInputs/TextInput"

const getOptions = (param: GeneralParam, configHex: string) => {
  if ("options" in param) {
    if (param.param_name === "input_layout_title") {
      const mode = getVal("mode", configHex).int
      const modeCompatibleOptions = param.options as OptionParamOption[]
      return modeCompatibleOptions
        .filter((entry) =>
          (mode < 2
            ? ["a_input_layout", "b_input_layout"]
            : ["ab_input_layout", "ba_input_layout"]
          ).includes(entry.name)
        )
        .map((entry) => ({
          displayValue: entry.name,
          value: entry.value,
        }))
    } else {
      return param.options.map((entry) =>
        typeof entry === "object"
          ? { displayValue: entry.name, value: entry.value }
          : { displayValue: entry.toString(), value: entry.toString() }
      )
    }
  } else return [{ value: "na_key", displayValue: "na_key" }]
}

const isEditable = (
  paramName: string,
  disabled: { canEdit: boolean; overrides: string[]; partials: string[] }
): boolean => {
  if (
    disabled.overrides.includes(paramName) ||
    disabled.partials.some((str) => paramName.includes(str))
  ) {
    return false
  } else if (disabled.canEdit) {
    return true
  } else return false
}

export const sectionSetter = (
  configHex: string,
  section: GeneralParam[],
  filter: { wholeNames: string[]; partial?: string },
  disabled: { canEdit: boolean; overrides: string[]; partials: string[] },
  updateParam: (paramName: string, displayValue: string) => void
): JSX.Element[] =>
  section
    .filter((param) => !filter.wholeNames.includes(param.param_name))
    .filter((param) => (filter.partial ? !param.param_name.includes(filter.partial) : true))
    .map((param, i) => {
      const commonProps = {
        key: i,
        setValue: (name: string, value: string) => updateParam(name, value),
        canEdit: isEditable(param.param_name, disabled),
        helperText: param.message ? ls.getText(param.message) : undefined,
      }

      switch (param.type) {
        case "OPTION":
          return (
            <SelectInput
              {...commonProps}
              title={param.param_name}
              currentValue={getVal(param.param_name, configHex).hex}
              options={getOptions(param, configHex)}
            />
          )
        case "SIGNED_INT":
        case "INT":
          return (
            <NumberInput {...commonProps} parameter={param as IntegerParam} configHex={configHex} />
          )
        default:
          return <TextInput {...commonProps} value={param.param_name} />
      }
    })
