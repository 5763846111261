import { useEffect, useState } from "react"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { languageSelector as ls } from "@covvi/language-selector"
import { FingerStatus } from "@typesFolder/remoteAssistTypes"
import { useCurrentHandContext } from "@context/remoteAssistProviders"

import Spinner from "@ui/spinners/Spinner"
import CVSubtitle from "@ui/text/CVSubtitle"

const HandStatusData = () => {
  const { handStatus, emitData } = useCurrentHandContext()
  const [expanded, setExpanded] = useState<string | false>("Triggers")

  useEffect(() => {
    emitData({ type: "streamStop", data: "status" })
    setTimeout(() => emitData({ type: "stream", data: "status" }), 250)
    return () => emitData({ type: "streamStop", data: "status" })
  }, [])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) =>
    setExpanded(newExpanded ? panel : false)

  const fingerArray: { title: string; data: FingerStatus | undefined }[] = [
    { title: "Thumb", data: handStatus?.thumbStatus },
    { title: "Index", data: handStatus?.indexStatus },
    { title: "Middle", data: handStatus?.middleStatus },
    { title: "Ring", data: handStatus?.ringStatus },
    { title: "Little", data: handStatus?.littleStatus },
    { title: "Rotation", data: handStatus?.rotationStatus },
  ]

  const triggerArray: { title: string; data: boolean | undefined }[] = [
    { title: "open_open", data: handStatus?.openOpenTrigger },
    { title: "hold_open", data: handStatus?.holdOpenTrigger },
    { title: "cocontraction", data: handStatus?.cocontractionTrigger },
    { title: "back_button_hold", data: handStatus?.backButtonTrigger },
    { title: "little_tip_hold", data: handStatus?.littleTrigger },
    { title: "ring_tip_hold", data: handStatus?.ringTrigger },
    { title: "middle_tip_hold", data: handStatus?.middleTrigger },
    { title: "index_tip_hold", data: handStatus?.indexTrigger },
    { title: "thumb_tip_hold", data: handStatus?.thumbTipTrigger },
    { title: "thumb_tap_out", data: handStatus?.thumbSupinateTrigger },
    { title: "thumb_tap_in", data: handStatus?.thumbPronateTrigger },
    { title: "Thumb Opposed", data: handStatus?.thumbOpposedTrigger },
  ]

  return (
    <div className={"p-4"}>
      <CVSubtitle
        text={ls.getText("Hand Status Data")}
        helperText={ls.getText("hand_status_section_help")}
      />
      <Accordion onChange={handleChange("Triggers")} expanded={expanded === "Triggers"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="trigger-content"
          id="trigger-header"
        >
          <Typography>{ls.getText("triggers")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {triggerArray.map((trigger, i) => {
              return (
                <TriggerDisplay
                  key={i}
                  enabled={trigger.data}
                  title={trigger.title}
                  type="success"
                />
              )
            })}
          </div>
        </AccordionDetails>
      </Accordion>

      {fingerArray.map((finger, i) => {
        return (
          <Accordion
            key={i}
            onChange={handleChange(finger.title)}
            expanded={expanded === finger.title}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${finger.title}-content`}
              id={`${finger.title}-header`}
            >
              <Typography>{ls.getText(finger.title)}</Typography>
            </AccordionSummary>
            {finger.data ? (
              <AccordionDetails>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                  <TriggerDisplay enabled={finger.data.moving} title={"Moving"} type="success" />
                  <TriggerDisplay
                    enabled={finger.data.currentLimit}
                    title={"Current Limit"}
                    type="success"
                  />
                  <TriggerDisplay
                    enabled={finger.data.atPosition}
                    title={"At Position"}
                    type="success"
                  />
                  <TriggerDisplay
                    enabled={finger.data.atClose}
                    title={"Fully Closed"}
                    type="success"
                  />
                  <TriggerDisplay
                    enabled={finger.data.atOpen}
                    title={"Fully Open"}
                    type="success"
                  />
                  <TriggerDisplay
                    enabled={finger.data.motorFault}
                    title={"Motor Fault"}
                    type="fault"
                  />
                  <TriggerDisplay
                    enabled={finger.data.motorStall}
                    title={"Motor Stall"}
                    type="stall"
                  />
                  <TriggerDisplay enabled={finger.data.overTemp} title={"Over Temp"} type="fault" />
                </div>
              </AccordionDetails>
            ) : (
              <Spinner />
            )}
          </Accordion>
        )
      })}
    </div>
  )
}

const TriggerDisplay = ({
  enabled,
  title,
  type,
}: {
  enabled: boolean | undefined
  title: string
  type: "stall" | "fault" | "success"
}) => {
  let signalState = "bg-gray-300"
  if (enabled === undefined) {
    signalState = "bg-red-300"
  } else if (enabled) {
    if (type === "success") signalState = "bg-green-400"
    if (type === "stall") signalState = "bg-amber-400"
    if (type === "fault") signalState = "bg-red-600"
  }
  return (
    <div className="flex flex-row my-3">
      <div className={`h-5 w-5 ${signalState} rounded-md`} />
      <span className="ml-3">{ls.getText(title)}</span>
    </div>
  )
}

export default HandStatusData
