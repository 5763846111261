import { useState, useEffect } from "react"

import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

import { languageSelector as ls } from "@covvi/language-selector"
import { toHex, getVal, IntegerParam, SignedIntegerParam } from "@covvi/common-functions"
import HelpTextTooltip from "@ui/buttons/HelpTextTooltip"

const NumberInput = ({
  configHex,
  parameter,
  setValue,
  canEdit = true,
  helperText,
}: {
  configHex: string
  parameter: IntegerParam | SignedIntegerParam
  setValue: (paramName: string, value: string) => void
  canEdit?: boolean
  helperText?: string
}) => {
  const [val, setVal] = useState<number>()
  let [increment, setIncrement] = useState<number>("step" in parameter ? parameter.step || 1 : 1)
  let [rangeMin, setRangeMin] = useState<number>(parameter.minimum || 0)
  let [rangeMax, setRangeMax] = useState<number>(parameter.maximum || 0)
  const paramName = parameter.param_name
  const coconTriggerIsSet = getVal("cocontraction", configHex).hex !== "00" ? true : false

  useEffect(() => {
    if ("unit" in parameter && parameter.unit === "mV") {
      const electrode = paramName.split("_")[1]
      setIncrement(100)
      if (paramName.endsWith("max_threshold")) {
        setRangeMax(getVal(`input_${electrode}_max_input`, configHex).int)
        if (coconTriggerIsSet) {
          setRangeMin(getVal(`input_${electrode}_cocon_threshold`, configHex).int)
        } else {
          setRangeMin(getVal(`input_${electrode}_on_threshold`, configHex).int)
        }
      } else if (paramName.endsWith("cocon_threshold")) {
        setRangeMin(getVal(`input_${electrode}_on_threshold`, configHex).int)
        setRangeMax(getVal(`input_${electrode}_max_threshold`, configHex).int)
      } else if (paramName.endsWith("max_input")) {
        setRangeMin(getVal(`input_${electrode}_max_threshold`, configHex).int)
        const maxVal = () => {
          const interfaceType = getVal("interface_type", configHex).hex
          if (interfaceType === "00") {
            return 5000
          } else if (interfaceType === "02") {
            return 3500
          } else if (interfaceType === "03") {
            return 8500
          } else return 5000
        }
        setRangeMax(maxVal())
      } else if (paramName.endsWith("on_threshold")) {
        setRangeMin(400) // minimum on threshold
        if (coconTriggerIsSet) {
          setRangeMax(getVal(`input_${electrode}_cocon_threshold`, configHex).int)
        } else {
          setRangeMax(getVal(`input_${electrode}_max_threshold`, configHex).int)
        }
      }
    }
    setVal(getVal(parameter.param_name, configHex).int)
  }, [parameter, configHex])

  useEffect(() => {
    if (val === undefined) {
      return
    } else if (val >= 0 && val !== getVal(parameter.param_name, configHex).int) {
      setValue(paramName, toHex(val, parameter.length))
    }
  }, [val])

  const display = () => {
    if (val === undefined) {
      return
    } else if (!("unit" in parameter)) {
      return val.toString()
    } else if (["s", "min."].includes(parameter.unit) && val === 0) {
      return ls.getText("off").toUpperCase()
    } else if (!coconTriggerIsSet && paramName.endsWith("cocon_threshold")) {
      return ls.getText("na_key")
    } else {
      return (
        (parameter.unit === "s" ? (val >>> 0) / 1000 : val).toString() +
        parameter.unit.replace(".", val > 1 ? "s" : "")
      )
    }
  }

  const handleChange = ({ increase }: { increase: boolean }) => {
    if (val === undefined) {
      return
    }
    let newVal = increase ? val + increment : val - increment
    if (newVal >= rangeMax) {
      setVal(rangeMax)
    } else if (newVal <= rangeMin) {
      setVal(rangeMin)
    } else {
      setVal(newVal)
    }
  }

  return (
    <div className="flex  sm:flex-row my-2 items-center space-y-2 sm:space-y-0 ">
      <div className="flex flex-1 items-center justify-self-start text-left w0">
        <p className="">{ls.getText(parameter.param_name)}</p>
      </div>
      <div className="items-end justify-self-end">
        <ButtonGroup
          className={"h-[40px]"}
          variant="contained"
          aria-label="outlined primary button group"
          sx={{
            "& .MuiButtonGroup-grouped": {
              "&:not(:last-of-type)": {
                border: 0,
              },
              "&:not(:last-of-type).Mui-disabled": {
                border: 0,
              },
            },
          }}
          disableElevation
        >
          <Button
            onClick={() => handleChange({ increase: false })}
            disabled={
              val === rangeMin ||
              !canEdit ||
              (!coconTriggerIsSet && paramName.endsWith("cocon_threshold"))
            }
          >
            <RemoveIcon />
          </Button>
          <div
            className={`flex items-center border border-copper  ${
              val && (val < rangeMin || val > rangeMax) ? "bg-red-500" : "bg-white"
            }`}
          >
            <h1 className={`w-[70px] text-center`}>{display()}</h1>
          </div>
          <Button
            onClick={() => handleChange({ increase: true })}
            disabled={
              val === rangeMax ||
              !canEdit ||
              (!coconTriggerIsSet && paramName.endsWith("cocon_threshold"))
            }
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
      </div>
      <div className="ml-2 justify-self-end">
        <HelpTextTooltip text={helperText || "NO-HELPTEXT"} />
      </div>
    </div>
  )
}

export default NumberInput
