import { useEffect, useState } from "react"
import validator from "validator"
import { TextField, MenuItem } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { generateTicketFromPortal, getSupportStructure } from "@util/firebase/supportFunctions"
import { useAuth } from "@context/AuthContext"
import { TicketStructure } from "@typesFolder/supportTypes"

import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCloseModal from "@ui/modals/CVCloseModal"
import BasicModal from "@ui/modals/BasicModal"
import { ButtonRow } from "@ui/sections/ButtonRow"
import CVSubtitle from "@ui/text/CVSubtitle"
import { Role } from "@covvi/common-functions"

interface ErrorObj {
  [key: string]: boolean
}

const NewTicketModal = ({
  setNewTicket,
  modalOpen,
  setModalOpen,
  setSuccess,
  setTicketError,
}: {
  setNewTicket: Function
  modalOpen: boolean
  setModalOpen: Function
  setSuccess: Function
  setTicketError: Function
}) => {
  const { profile } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [structure, setStructure] = useState<TicketStructure[] | undefined>()
  const [products, setProducts] = useState<string[]>([])
  const [issues, setIssues] = useState<string[]>([])
  const [subissues, setSubissues] = useState<string[]>([])
  const [error, setError] = useState<string[]>([])

  const [product, setProduct] = useState<string>("")
  const [issue, setIssue] = useState<string>("")
  const [subissue, setSubissue] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [appVer, setAppVer] = useState<string>("")
  const [deviceType, setDeviceType] = useState<"Smartphone" | "Tablet" | "">("")
  const [operatingSystem, setOperatingSystem] = useState<"iOS" | "Android" | "">("")
  const [firmware, setFirmware] = useState("")
  const [serialNumber, setSerialNumber] = useState<string>("")
  const [errorCode, setErrorCode] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [emailAddress, setEmailAddress] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [role, setRole] = useState<Role | "">("")

  const [errors, setErrors] = useState<ErrorObj>({})

  useEffect(() => {
    profile &&
      getSupportStructure()
        .then(setStructure)
        .catch((error) => {
          profile.role === "Admin" && console.log(error)
        })
  }, [profile])

  useEffect(() => {
    if (structure !== undefined) {
      let prodArray: string[] = []
      structure.forEach((product: TicketStructure) => {
        prodArray.push(product.name)
      })
      setProducts(prodArray)
      setProduct(prodArray[0])
    }
  }, [structure])

  useEffect(() => {
    if (product) {
      let issueArray = ["None relevant"]
      structure &&
        structure.forEach((strucProduct: TicketStructure) => {
          if (strucProduct.name === product) {
            strucProduct.options.forEach((issue: TicketStructure) => {
              issueArray.push(issue.name)
            })
          }
        })
      setIssues(issueArray)
    }
  }, [structure, product])

  useEffect(() => {
    if (!(issue === undefined || issue === "None relevant")) {
      let subIssueArray = ["None relevant"]
      structure &&
        structure.forEach((strucProduct: TicketStructure) => {
          if (strucProduct.name === product) {
            strucProduct.options.forEach((strucIssue: TicketStructure) => {
              if (strucIssue.name === issue) {
                strucIssue.options.forEach((strucSubissue: TicketStructure) => {
                  subIssueArray.push(strucSubissue.name)
                })
              }
            })
          }
        })
      setSubissues(subIssueArray)
    }
  }, [structure, product, issue])

  const generateObject = (value: string) => {
    if (!value) {
      return { id: "", name: "" }
    } else {
      return { id: value.toLowerCase().replace(" ", "_"), name: value }
    }
  }
  const createNewTicket = () => {
    setIsLoading(true)
    let isValid = validateForm()

    if (isValid === false) {
      setIsLoading(false)
    } else {
      generateTicketFromPortal({
        ticket: {
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
          date: new Date(),
          product: generateObject(product),
          issue: generateObject(issue),
          subissue: generateObject(subissue),
          description: description || "",
          appVersion: appVer,
          deviceType,
          operatingSystem,
          firmware,
          serialNumber,
          phone: phoneNumber,
          role,
          errorCode,
        },
        role: profile!.role,
      })
        .then((response) => {
          setNewTicket(response.id)
          setIsLoading(false)
          setSuccess(`${ls.getText("ticket_generated")} \n ${ls.getText("Ticket")} #${response.id}`)
          setModalOpen(false)
        })
        .catch((error) => {
          setIsLoading(false)
          setTicketError(error)
        })
    }
  }

  const validateForm = () => {
    let validationErrors: ErrorObj = {}

    if (product === "") {
      validationErrors.product = true
    }
    if (issue === "") {
      validationErrors.issue = true
    }
    if (subissue === "") {
      validationErrors.subissue = true
    }
    if (firstName === "" || firstName.length < 2) {
      validationErrors.firstName = true
    }
    if (lastName === "" || lastName.length < 2) {
      validationErrors.lastName = true
    }
    if (!validator.isEmail(emailAddress)) {
      validationErrors.emailAddress = true
    }

    let isError = false
    for (const prop in validationErrors) {
      if (validationErrors[prop as keyof typeof errors] === true) {
        isError = true
      }
    }
    setErrors({ ...validationErrors })

    return !isError
  }

  return (
    <>
      <BasicModal open={modalOpen} setClosed={() => setModalOpen(false)}>
        <CVCardHeader>
          <CVCardTitle title={ls.getText("Submit A Support Ticket")} />
          <CVCloseModal setShowingModal={setModalOpen} />
        </CVCardHeader>
        <CVCardBody>
          <div className="flex flex-col py-[25px] px-[30px] space-y-[30px]">
            <CVSubtitle text={ls.getText("Ticket Details")} />
            <TextField
              error={errors.product}
              helperText={errors.product ? ls.getText("Please Choose a Product") : ""}
              variant="outlined"
              label={ls.getText("Product")}
              value={product}
              select
              onChange={(e) => setProduct(e.target.value)}
              required
            >
              <MenuItem disabled value="">
                {ls.getText("Select a Product")}
              </MenuItem>
              {products.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              error={errors.issue}
              helperText={errors.issue ? ls.getText("Please Select an Issue") : ""}
              variant="outlined"
              label={ls.getText("Issue")}
              value={issue}
              select
              onChange={(e) => setIssue(e.target.value)}
              required
            >
              <MenuItem disabled value="">
                {ls.getText("Select an Issue")}
              </MenuItem>
              {issues.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className=""
              error={errors.subissue}
              helperText={errors.subissue ? ls.getText("Please Select a Sub-Issue") : ""}
              variant="outlined"
              label={ls.getText("Sub-Issue")}
              value={subissue}
              select
              onChange={(e) => setSubissue(e.target.value)}
              required
            >
              <MenuItem disabled value="">
                {ls.getText("Select a Sub-Issue")}
              </MenuItem>
              {subissues.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className=""
              variant="outlined"
              label={ls.getText("Description")}
              value={description}
              multiline
              minRows={3}
              onChange={(e) => setDescription(e.target.value)}
            />
            {product === "COVVI Go" && (
              <TextField
                className="basis-full sm:basis-[47%]"
                variant="outlined"
                label={ls.getText("App Version")}
                value={appVer}
                onChange={(e) => setAppVer(e.target.value)}
              />
            )}
            <div className="flex flex-row flex-wrap gap-y-[20px] justify-between">
              {product === "COVVI Go" && (
                <>
                  <TextField
                    className="basis-full sm:basis-[47%]"
                    variant="outlined"
                    label={ls.getText("Device Type")}
                    value={deviceType}
                    select
                    onChange={(e) => setDeviceType(e.target.value as "Smartphone" | "Tablet" | "")}
                  >
                    <MenuItem value="">{ls.getText("Select a Device Type")}</MenuItem>
                    <MenuItem value={"Smartphone"}>{ls.getText("Smartphone")}</MenuItem>
                    <MenuItem value={"Tablet"}>{ls.getText("Tablet")}</MenuItem>
                  </TextField>

                  <TextField
                    className="basis-full sm:basis-[47%]"
                    variant="outlined"
                    label={ls.getText("Operating System")}
                    value={operatingSystem}
                    select
                    onChange={(e) => setOperatingSystem(e.target.value as "iOS" | "Android" | "")}
                  >
                    <MenuItem value="">{ls.getText("Select an Operating System")}</MenuItem>
                    <MenuItem value={"iOS"}>{ls.getText("iOS")}</MenuItem>
                    <MenuItem value={"Android"}>{ls.getText("Android")}</MenuItem>
                  </TextField>
                </>
              )}
            </div>
            {product === "COVVI Hand" && (
              <>
                <div className="flex flex-row flex-wrap gap-y-[20px] justify-between">
                  <TextField
                    className="basis-full sm:basis-[47%]"
                    variant="outlined"
                    label={ls.getText("Firmware Version")}
                    value={firmware}
                    onChange={(e) => setFirmware(e.target.value)}
                  />
                  <TextField
                    className="basis-full sm:basis-[47%]"
                    variant="outlined"
                    label={ls.getText("hand_serial_number_name")}
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                  />
                  <TextField
                    className="basis-full"
                    variant="outlined"
                    label={ls.getText("Error Code")}
                    value={errorCode}
                    onChange={(e) => setErrorCode(e.target.value)}
                  />
                </div>
              </>
            )}
            <CVSubtitle text={ls.getText("User Details")} />
            <div className="flex flex-row flex-wrap gap-y-[20px] justify-between">
              <TextField
                className="basis-full sm:basis-[47%]"
                error={errors.firstName}
                helperText={errors.firstName ? ls.getText("Please enter a first name") : ""}
                variant="outlined"
                label={ls.getText("First Name")}
                value={firstName}
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%] self-end"
                error={errors.lastName}
                helperText={errors.lastName ? ls.getText("Please enter a last name") : ""}
                variant="outlined"
                label={ls.getText("Last Name")}
                value={lastName}
                required
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                className="basis-full"
                error={errors.emailAddress}
                helperText={
                  errors.emailAddress ? ls.getText("Please enter a valid email address") : ""
                }
                variant="outlined"
                label={ls.getText("Email Address")}
                value={emailAddress}
                required
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%]"
                variant="outlined"
                label={ls.getText("Contact Number")}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%]"
                variant="outlined"
                label={ls.getText("Role")}
                value={role}
                select
                onChange={(e) => setRole(e.target.value as Role | "")}
              >
                <MenuItem value="">{ls.getText("Please Select a Role")}</MenuItem>
                <MenuItem value={"Distributor"}>{ls.getText("Distributor")}</MenuItem>
                <MenuItem value={"Clinician"}>{ls.getText("Clinician")}</MenuItem>
                <MenuItem value={"User"}>{ls.getText("User")}</MenuItem>
              </TextField>
            </div>
          </div>
        </CVCardBody>
        <CVCardFooter>
          <div className="flex flex-1 my-[100px] sm:my-0">
            <ButtonRow
              buttonArray={[
                {
                  title: ls.getText("Submit"),
                  variant: "contained",
                  loading: isLoading,
                  onClick: () => createNewTicket(),
                },
                {
                  title: ls.getText("Cancel"),
                  variant: "outlined",
                  onClick: () => setModalOpen(false),
                },
              ]}
            />
          </div>
        </CVCardFooter>
      </BasicModal>
    </>
  )
}

export default NewTicketModal
