import { useEffect, useState } from "react"
import { TextField, MenuItem, Autocomplete } from "@mui/material"
import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { checkProtectedConfigNames, Config, ConfigVersion, Role } from "@covvi/common-functions"

import BasicModal from "@ui/modals/BasicModal"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import CVCardTitle from "@ui/text/CVCardTitle"
import CVCloseModal from "@ui/modals/CVCloseModal"
import SaveConfigConfirmation from "./SaveConfigConfirmation"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { getListOfHands, uploadConfigHistory } from "@util/firebase/handFunctions/configFunctions"

interface Props {
  showingModal: boolean
  setShowingModal: Function
  configData: Config | undefined
  newHex: string | undefined
  handName: string
  thisConfigName: string | undefined
  configNamesList: string[]
  setAlert: Function
}

const SaveConfigModal = ({
  showingModal,
  setShowingModal,
  configData,
  newHex,
  handName,
  thisConfigName,
  configNamesList,
  setAlert,
}: Props) => {
  const { profile } = useAuth()
  const isCovvi = [
    "Sales Team Member",
    "Customer Service Team Member",
    "Tech Team Member",
    "Admin",
  ].includes(profile!.role)

  const [handNameList, setHandNameList] = useState<string[] | undefined>([])
  const [handNameOverwrite, setHandNameOverwrite] = useState<string>(handName)
  const [handNameError, setHandNameError] = useState<string | undefined>()
  const [newConfigName, setNewConfigName] = useState<string | undefined>(
    isCovvi ? "COVVI Support" : thisConfigName
  )
  const [newConfigNameError, setNewConfigNameError] = useState<string | undefined>()
  const [saving, setSaving] = useState<boolean>(false)
  const [showingConfirmationModal, setShowingConfirmationModal] = useState<boolean>(false)

  useEffect(() => {
    if (thisConfigName === "Live Config") {
      setNewConfigName("")
    }
  }, [thisConfigName])

  useEffect(() => {
    if (profile && isCovvi) {
      getListOfHands(profile).then((hands) => {
        setHandNameList(hands.map((hand) => hand.serialNumber) as string[])
      })
    } else if ((profile && profile.role === "Clinician") || (profile && profile.role === "User")) {
      setHandNameList(profile.associated_hands)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  useEffect(() => {
    if (handNameOverwrite !== undefined && handNameList) {
      handNameList.includes(handNameOverwrite)
        ? setHandNameError(undefined)
        : setHandNameError("Error")
    }
  }, [handNameOverwrite, handNameList])

  useEffect(() => {
    if (newConfigName === undefined || newConfigName === "") {
      setNewConfigNameError(ls.getText("must_name"))
    } else if (newConfigName && profile) {
      let nameErrors = checkProtectedConfigNames(newConfigName, profile?.role as Role)
      setNewConfigNameError(nameErrors)
    }
  }, [newConfigName, profile])

  const updateConfig = async () => {
    if (configData && profile) {
      if (handName && newConfigName) {
        setSaving(true)
        let data: Config = {
          configHex: newHex || configData.configHex,
          configVersion: configData.configVersion?.toString() as ConfigVersion | undefined,
          firmwareVersion: configData.firmwareVersion || ls.getText("Unknown"),
          serialNumber: handNameOverwrite,
          name: newConfigName || ls.getText("Unknown"),
          setBy: profile.uid,
          date: { seconds: Math.round(Date.now() / 1000), nanoseconds: 0 },
          ...(configData.configVersion &&
            configData.configVersion >= 105 &&
            configData.userGrips && { userGrips: configData.userGrips }),
          userAppVersion: `COVVI Portal ${process.env.REACT_APP_VERSION}`,
        }
        if (handName.toLowerCase() !== handNameOverwrite.toLowerCase()) {
          data.configImportedFrom = handName
        }
        uploadConfigHistory(data, profile)
          .then(() => {
            setSaving(false)
            setAlert("success")
            setShowingModal(false)
          })
          .catch((error) => {
            setSaving(false)
            setAlert(error)
          })
      } else {
        setAlert("error")
      }
    }
  }

  const handleButtonText = () => {
    if (newConfigName === "COVVI Support") {
      return ls.getText("Save Config")
    } else if (newConfigName && configNamesList.includes(newConfigName)) {
      return ls.getText("Update Config")
    } else {
      return ls.getText("save_new")
    }
  }

  return (
    <BasicModal fullWidth={true} open={showingModal} setClosed={() => setShowingModal(false)}>
      <CVCardHeader>
        <CVCardTitle title={`${ls.getText("Save Config as")}...`} />
        <CVCloseModal setShowingModal={setShowingModal} />
      </CVCardHeader>
      <CVCardBody>
        <div className="p-[30px] flex flex-col min-h-[200px] space-y-6 justify-between">
          <div className="flex flex-col sm:flex-row justify-between min-h-[70px]">
            <h1 className="text-black p-3">{ls.getText("Saving Config as")}</h1>
            <div className="flex flex-row">
              <TextField
                className="flex-1 sm:w-[300px]"
                error={!(newConfigNameError === undefined || newConfigNameError === "")}
                helperText={(newConfigNameError && ls.getText(newConfigNameError)) || ""}
                variant="outlined"
                onChange={(e) => setNewConfigName(e.target.value)}
                size="small"
                value={newConfigName}
              >
                {newConfigName}
              </TextField>
              {(profile?.role === "Admin" || profile?.role === "Tech Team Member") && (
                <TextField
                  select
                  className="w-[38px]"
                  size="small"
                  onChange={(e) => {
                    setNewConfigName(e.target.value)
                  }}
                  value={""}
                >
                  <MenuItem key={"COVVI Support"} value={"COVVI Support"}>
                    {ls.getText("COVVI Support")}
                  </MenuItem>
                  {configNamesList.map((configName: string) => {
                    return (
                      <MenuItem key={configName} value={configName}>
                        {`${configName}${
                          thisConfigName === configName
                            ? " (" + ls.getText("This Config") + ")"
                            : ""
                        }`}
                      </MenuItem>
                    )
                  })}
                </TextField>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between min-h-[70px]">
            <h1 className="text-black p-3">{ls.getText("To hand")}</h1>
            <div className="flex flex-row">
              {(profile?.role === "Admin" || profile?.role === "Tech Team Member") &&
              handNameList ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={handNameList}
                  size="small"
                  sx={{ width: 300 }}
                  defaultValue={handName}
                  onChange={(event: any, e: string | null) => e && setHandNameOverwrite(e)}
                  renderInput={(params) => (
                    <TextField {...params} error={handNameError !== undefined} />
                  )}
                />
              ) : (
                <TextField
                  className="flex-1 sm:w-[300px]"
                  error={handNameError !== undefined}
                  helperText={handNameError}
                  variant="outlined"
                  onChange={(e) => setHandNameOverwrite(e.target.value)}
                  size="small"
                  value={handNameOverwrite}
                  disabled={profile?.role !== "Admin" && profile?.role !== "Tech Team Member"}
                >
                  {handNameOverwrite}
                </TextField>
              )}
            </div>
          </div>
        </div>
      </CVCardBody>
      <CVCardFooter>
        <>
          <ButtonRow
            buttonArray={[
              {
                title: handleButtonText(),
                loading: saving,
                onClick: () => setShowingConfirmationModal(true),
                disabled:
                  handNameError !== undefined ||
                  newConfigNameError === undefined ||
                  newConfigNameError !== "",
                variant: "contained",
              },
              {
                variant: "outlined",
                onClick: () => setShowingModal(false),
                title: ls.getText("Cancel"),
              },
            ]}
          />
        </>
      </CVCardFooter>
      {showingConfirmationModal && (
        <SaveConfigConfirmation
          showingConfirmationModal={showingConfirmationModal}
          setShowingConfirmationModal={setShowingConfirmationModal}
          updateConfig={updateConfig}
          saving={saving}
        />
      )}
    </BasicModal>
  )
}

export default SaveConfigModal
