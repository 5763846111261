import { TextField } from "@mui/material"

import { languageSelector as ls } from "@covvi/language-selector"
import { TicketWithStatus } from "@typesFolder/supportTypes"
import CVSubtitle from "@ui/text/CVSubtitle"

interface Props {
  ticketInfo: TicketWithStatus | undefined
}

const TicketInfo = ({ ticketInfo }: Props) => {
  return (
    <>
      <div className="flex flex-col py-[25px] px-[30px] space-y-[30px]">
        <CVSubtitle text={ls.getText("Ticket Details")} />
        <div className="flex flex-row flex-wrap gap-y-[20px] justify-between">
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Product")}
            value={ticketInfo?.product.name || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Issue")}
            value={ticketInfo?.issue.name || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full"
            variant="outlined"
            label={ls.getText("Sub-Issue")}
            value={ticketInfo?.subissue?.name || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full"
            variant="outlined"
            label={ls.getText("Description")}
            value={ticketInfo?.description || ls.getText("None given")}
            disabled
            multiline
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("App Version")}
            value={ticketInfo?.appVersion || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Device Type")}
            value={ticketInfo?.deviceType || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Operating System")}
            value={ticketInfo?.operatingSystem || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Firmware Version")}
            value={ticketInfo?.firmware || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("hand_serial_number_name")}
            value={ticketInfo?.serialNumber || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Error Code")}
            value={ticketInfo?.errorCode || ls.getText("None given")}
            disabled
          />
        </div>
        <CVSubtitle text={ls.getText("User Details")} />
        <div className="flex flex-row flex-wrap gap-y-[20px] justify-between">
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("First Name")}
            value={ticketInfo?.firstName || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%] self-end"
            variant="outlined"
            label={ls.getText("Last Name")}
            value={ticketInfo?.lastName || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full"
            variant="outlined"
            label={ls.getText("Email Address")}
            value={ticketInfo?.emailAddress || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Contact Number")}
            value={ticketInfo?.phone || ls.getText("None given")}
            disabled
          />
          <TextField
            className="basis-full sm:basis-[47%]"
            variant="outlined"
            label={ls.getText("Role")}
            value={ticketInfo?.role || ls.getText("None given")}
            disabled
          />
        </div>
      </div>
    </>
  )
}

export default TicketInfo
