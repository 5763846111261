import { Fragment, useEffect, useState } from "react"
import { langToLong, PossibleLangLong, PossibleLangShort } from "@covvi/common-functions"
import { languageSelector as ls } from "@covvi/language-selector"
import { TextField } from "@mui/material"
import { DisplayTranslation, StoredTranslation } from "@typesFolder/types"
import ButtonWithLoading from "@ui/buttons/ButtonWithLoading"
import BasicModal from "@ui/modals/BasicModal"
import CVCloseModal from "@ui/modals/CVCloseModal"
import { ButtonRow } from "@ui/sections/ButtonRow"
import CVCardBody from "@ui/sections/CVCardBody"
import CVCardFooter from "@ui/sections/CVCardFooter"
import CVCardHeader from "@ui/sections/CVCardHeader"
import Spinner from "@ui/spinners/Spinner"
import CVCardTitle from "@ui/text/CVCardTitle"

const ViewTranslation = ({
  translation,
  updateTranslation,
  showingModal,
  setShowingModal,
  languages,
  getContext,
}: {
  showingModal: boolean
  setShowingModal: Function
  languages: { long: PossibleLangLong[]; short: PossibleLangShort[] }
  translation: StoredTranslation
  updateTranslation: (
    uid: string,
    language: PossibleLangShort,
    translation: string
  ) => Promise<void>
  getContext: (translation: StoredTranslation) => Promise<void>
}) => {
  const [translations, setTranslations] = useState<DisplayTranslation>({
    key: translation.key,
    en: translation.en.translation,
  })

  const [loading, setLoading] = useState<undefined | { [lang: string]: boolean }>()
  const [loadingContext, setLoadingContext] = useState(false)
  const [status, setStatus] = useState<undefined | string>()

  useEffect(() => {
    languages.short.forEach((language) => {
      setLoading((current) => {
        return {
          ...current,
          [language]: false,
        }
      })
      setTranslations((current) => {
        return { ...current, [language]: translation[language]?.translation }
      })
    })
  }, [translation])

  const handleUpdate = async (lang: PossibleLangShort) => {
    setLoading((current) => {
      return {
        ...current,
        [lang]: true,
      }
    })
    await updateTranslation(translation.key, lang as PossibleLangShort, translations[lang])
      .then(() => setStatus(`${ls.getText(langToLong(lang))} ${ls.getText("trans_updated")}`))
      .catch(() => setStatus(ls.getText("trans_error")))
    setLoading((current) => {
      return {
        ...current,
        [lang]: false,
      }
    })
  }

  const handleContextRequest = async () => {
    setLoadingContext(true)
    await getContext(translation)
      .then(() => setStatus(ls.getText("Context requested")))
      .catch(() => setStatus(ls.getText("context_error")))
    setLoadingContext(false)
  }

  return translations ? (
    <BasicModal open={showingModal} setClosed={() => setShowingModal()} fullWidth={true}>
      <CVCardHeader>
        <CVCardTitle title={ls.getText("Edit Translation")} />
        <CVCloseModal setShowingModal={setShowingModal} />
      </CVCardHeader>
      <CVCardBody>
        <div className="p-[30px] flex flex-col flex-1 space-y-2">
          <div className="flex flex-row space-x-2 pb-4">
            <div className="font-bold text-lg pr-4">{ls.getText("English")}:</div>
            <div className="font-semibold text-lg">{translations.en}</div>
          </div>

          {Object.entries(translations).map(([k, v], i) => {
            const key = k as keyof DisplayTranslation
            const entry = v as string
            if (["key", "en", "context"].includes(key)) {
              return <Fragment key={i} />
            } else {
              return (
                <div className="flex flex-1 space-x-2" key={i}>
                  <TextField
                    className="basis-full self-end"
                    variant="outlined"
                    label={ls.getText(langToLong(key as PossibleLangShort))}
                    value={entry || ""}
                    onChange={(e) =>
                      setTranslations((current) => ({ ...current, [key]: e.target.value }))
                    }
                  />
                  <div className="my-auto flex flex-row">
                    <ButtonWithLoading
                      title={ls.getText("Update")}
                      loading={(loading && loading[key as PossibleLangShort]) || false}
                      onClick={() => handleUpdate(key as PossibleLangShort)}
                    />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </CVCardBody>
      <CVCardFooter>
        <div className="px-8 spacing-x-4 flex flex-row">
          <ButtonWithLoading
            title={ls.getText("Context")}
            loading={loadingContext}
            onClick={() => handleContextRequest()}
          />
          <div className="px-4 my-auto">{status}</div>
        </div>
        <ButtonRow
          buttonArray={[
            {
              variant: "outlined",
              onClick: () => setShowingModal(),
              title: ls.getText("Close"),
            },
          ]}
        />
      </CVCardFooter>
    </BasicModal>
  ) : (
    <div className="flex flex-row">
      <div className="p-4">{ls.getText("Loading")}</div>
      <Spinner />
    </div>
  )
}

export default ViewTranslation
