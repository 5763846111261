import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Alert, { AlertColor } from "@mui/material/Alert"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"

import HandConfigSection from "@components/sections/HandConfigSection"

import Spinner from "@ui/spinners/Spinner"
import BasicModal from "@ui/modals/BasicModal"
import SaveConfigModal from "@components/modals/hands/SaveConfigModal"
import DeleteConfigConfirmation from "@components/modals/hands/DeleteConfigConfirmation"
import { ButtonObj } from "@typesFolder/types"
import { Config, ProfileData } from "@covvi/common-functions"
import { getHandConfigs } from "@util/firebase/handFunctions/configFunctions"
import { logEvent } from "firebase/analytics"
import { analytics } from "@util/firebase/firebase"

const ViewHandConfig = () => {
  const { profile } = useAuth()
  const { handId, config } = useParams() as { handId: string; config: string }

  const [newHex, setNewHex] = useState<undefined | string>()
  const [alert, setAlert] = useState<AlertColor | undefined>()
  const [configData, setConfigData] = useState<Config | undefined>()
  const [showingDeleteConfig, setShowingDeleteConfig] = useState<boolean>(false)
  const [deletionAlert, setDeletionAlert] = useState<AlertColor | undefined>()
  const [showingSaveConfigAs, setShowingSaveConfigAs] = useState<boolean>(false)
  const [configNamesList, setConfigNamesList] = useState<string[]>([])

  useEffect(() => logEvent(analytics, "page_hand_config", { uid: profile?.uid, handId }), [])

  useEffect(() => {
    setConfigData((current) => {
      return current !== undefined && newHex !== undefined
        ? { ...current, configHex: newHex }
        : undefined
    })
  }, [newHex])

  useEffect(() => {
    handId &&
      getHandConfigs(handId, profile as ProfileData).then((configHistories) => {
        let arr: string[] = []
        configHistories.forEach((history) => {
          if (!history.name?.startsWith("ARCHIVED") && history.name !== "COVVI Support") {
            arr.push(history.name)
          }
        })
        const configToView: Config | undefined = configHistories.filter((x) => x.name === config)[0]
        configToView && setConfigData(configToView)
        setConfigNamesList(arr)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  const shouldRenderDeleteConfigButton = () => {
    if (config === "Live Config") return false
    if (profile?.role === "Admin") return true
    if (configData?.name?.startsWith("ARCHIVED")) return false
    if (profile?.role === "Tech Team Member") return true
    if (configData?.setBy === profile?.uid) return true
    if (configData?.setBy?.toLowerCase() === profile?.email_address.toLowerCase()) return true // shouldnt be needed after script
    return false
  }

  const canEdit = true

  const buttonArray: ButtonObj[] = []

  canEdit &&
    buttonArray.push({
      title: ls.getText("Save Config as"),
      onClick: () => {
        setShowingSaveConfigAs(true)
      },
      disabled: profile!.role === "Clinician" && !newHex,
      variant: "contained",
    })

  shouldRenderDeleteConfigButton() &&
    buttonArray.push({
      variant: "outlined",
      title: ls.getText("Delete Config"),
      color: "error",
      onClick: () => setShowingDeleteConfig(true),
    })

  return configData ? (
    <>
      <HandConfigSection
        config={configData}
        canEdit={canEdit}
        setCurrentConfig={setNewHex}
        buttonArray={buttonArray}
      />

      {showingDeleteConfig && (
        <DeleteConfigConfirmation
          showingModal={showingDeleteConfig}
          setShowingModal={setShowingDeleteConfig}
          config={configData}
          serialNumber={handId}
          setAlert={setDeletionAlert}
        />
      )}
      {showingSaveConfigAs && (
        <SaveConfigModal
          showingModal={showingSaveConfigAs}
          setShowingModal={setShowingSaveConfigAs}
          configData={configData}
          newHex={newHex}
          handName={handId}
          thisConfigName={config}
          configNamesList={configNamesList}
          setAlert={setAlert}
        />
      )}
      {alert && (
        <BasicModal
          open={alert ? true : false}
          setClosed={() => {
            setAlert(undefined)
          }}
        >
          <Alert
            severity={alert}
            onClose={() => {
              setAlert(undefined)
            }}
          >
            {alert === "error" ? ls.getText("error_setting_config") : ls.getText("config_success")}
          </Alert>
        </BasicModal>
      )}
      {deletionAlert && (
        <BasicModal
          open={deletionAlert ? true : false}
          setClosed={() => {
            setDeletionAlert(undefined)
          }}
        >
          <Alert
            severity={deletionAlert}
            onClose={() => {
              setDeletionAlert(undefined)
            }}
          >
            {deletionAlert === "error"
              ? ls.getText("alerts_error_removing_config_title")
              : ls.getText("delete_success")}
          </Alert>
        </BasicModal>
      )}
    </>
  ) : (
    <Spinner />
  )
}

export default ViewHandConfig
