import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { languageSelector as ls } from "@covvi/language-selector"
import Spinner from "@ui/spinners/Spinner"
import CVSubtitle from "@ui/text/CVSubtitle"
import { stringDate, titleCase } from "@covvi/common-functions"

ChartJS.register(CategoryScale, LinearScale, LogarithmicScale, BarElement, Title, Tooltip, Legend)

const Graph = ({
  graphData,
  pageTitle,
  isLogarithmic = false,
}: {
  graphData: { title: string; data: { [key: string]: number } }
  pageTitle: string
  isLogarithmic?: boolean
}) => {
  const handleData = (graphDataObj: { [key: string]: number }) => {
    if (new Date(parseInt(Object.keys(graphData.data)[0])).toString() === "Invalid Date") {
      return {
        labels: Object.entries(graphDataObj)
          .sort((a, b) => b[1] - a[1])
          .map(([key, value]) => [
            titleCase(
              ls
                .getText(key)
                .split(/(?=[A-Z])/)
                .join(" ")
                .replaceAll("_", " ")
            ),
          ]),
        datasets: [
          {
            data: Object.entries(graphDataObj)
              .sort((a, b) => b[1] - a[1])
              .map(([key, value]) => graphDataObj[key]),
            backgroundColor: "#c57c46",
          },
        ],
      }
    } else {
      return {
        labels: Object.keys(graphDataObj).map((key) =>
          stringDate(parseInt(key)).replace(",", "").split(" ").slice(0, 3)
        ),
        datasets: [{ data: Object.values(graphDataObj), backgroundColor: "#c57c46" }],
      }
    }
  }

  return graphData ? (
    <div className="flex flex-col p-5 h-full w-full">
      <div className="self-center capitalize">
        <CVSubtitle
          text={
            graphData.title === "Data Usage" || pageTitle === "Grips"
              ? `${ls.getText(graphData.title).replaceAll("_", " ")} (seconds)`
              : ls.getText(graphData.title).replaceAll("_", " ")
          }
        />
      </div>
      <div className="flex flex-col flex-1 min-w-[800px] pr-5">
        <Bar
          style={{ height: "500px" }}
          className={`py-4 overflow-x-auto`}
          updateMode="resize"
          options={{
            // responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top" as const,
                display: false,
              },
            },
            scales: {
              x: {
                display: true,
                ticks: {
                  // minRotation: 90,
                  // minRotation: 40,
                  // maxRotation: 0,
                },
              },
              y: isLogarithmic
                ? {
                    display: true,
                    type: "logarithmic",
                  }
                : {
                    ticks: {
                      precision: 0,
                    },
                    display: true,
                    beginAtZero: Object.values(graphData.data).filter((value) => value > 0)[0]
                      ? false
                      : true,
                  },
            },
          }}
          data={handleData(graphData.data)}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default Graph
