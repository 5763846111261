import { createContext, useEffect, useRef, useState } from "react"
import { io, Socket } from "socket.io-client"
import { useLocation, useNavigate } from "react-router-dom"

import { Config } from "@covvi/common-functions"
import { getIdToken } from "firebase/auth"
import { useAuth } from "./AuthContext"
import {
  ContextState,
  DataEmission,
  Questionnaire,
  Room,
  SessionData,
  UpdateConnection,
  HandStatus,
  DigitPositions,
  CurrentView,
  ErrorCodeResponse,
  ErrorLogs,
} from "@typesFolder/remoteAssistTypes"
import { RASConfig } from "@typesFolder/types"
import { sendConfigVersion100ProbableErrorEmail } from "@util/firebase/handFunctions/handFunctions"
import { rasMakeDate } from "@util/configFunctions/rasMakeDate"

const RemoteAssistContext = createContext<ContextState | undefined>(undefined)

const RemoteAssistProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, profile } = useAuth()

  const [authToken, setAuthToken] = useState<string | undefined>()
  const [socket, setSocket] = useState<Socket | undefined>()
  const [contextError, setContextError] = useState<string | undefined>()
  const [configError, setConfigError] = useState<string | undefined>()
  const [liveRooms, setLiveRooms] = useState<Room[] | undefined>()
  const [archivedRooms, setArchivedRooms] = useState<Room[] | undefined>()
  const [roomId, setRoomId] = useState<string | undefined>()
  const [client, setClient] = useState<string | undefined>()
  const [currentView, setCurrentView] = useState<CurrentView>("Config")
  const [isInCustomBLECommand, setIsInCustomBLECommand] = useState<boolean>(false)
  const [isInConfigActions, setIsInConfigActions] = useState<boolean>(false)
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | undefined>()

  const [initialConfig, setInitialConfig] = useState<Config | undefined>()
  const [currentConfig, setCurrentConfig] = useState<Config | undefined>()
  const [elecA, setElecA] = useState<number>(0)
  const [elecB, setElecB] = useState<number>(0)
  const [handStatus, setHandStatus] = useState<HandStatus | undefined>()
  const [digitPositions, setDigitPositions] = useState<DigitPositions | undefined>()
  const [commandResponse, setCommandResponse] = useState<string | undefined>()
  const [electrodeUpdate, setElectrodeUpdate] = useState<string>()
  const [errorLogs, setErrorLogs] = useState<ErrorLogs>()
  const [defaultConfigErrorRoomIDArray, setDefaultConfigErrorRoomIDArray] = useState<string[]>([])
  const currentErrorsLength = useRef(0)
  let errorChecker: NodeJS.Timeout

  useEffect(() => {
    const validRoom =
      location.pathname.split("/")[2]?.length === 6 &&
      !isNaN(Number(location.pathname.split("/")[2]))
    validRoom && !roomId && navigate("/remote-assist")
    return () => {
      validRoom && leaveRoom()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (window.location.pathname.split("/")[1] !== "remote-assist") return
    if (user && profile) {
      const socket: () => string = () => {
        if (window.location.hostname === "localhost") {
          return `http://localhost:8080`
        } else if (process.env.REACT_APP_ENV === "staging") {
          return "https://staging.remote.covvi.com"
        } else {
          return "https://remote.covvi.com"
        }
      }
      getIdToken(user)
        .then((authToken: string) => {
          setAuthToken(authToken)
          setSocket(io(socket()))
        })
        .catch(setContextError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, user])

  useEffect(() => {
    socket?.removeAllListeners()
    socket?.on("connect", connectToServer)
    socket?.on("disconnect", disconnected)
    socket?.on("connect_error", (error) => disconnected(error.message))
    socket?.on("data", dataFromClient)
    socket?.on("serverMessage", setContextError)
    socket?.on("connectionReport", updateConnection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    socket,
    roomId,
    initialConfig,
    currentConfig,
    location.pathname,
    isInCustomBLECommand,
    isInConfigActions,
  ])

  const sendDefaultConfigErrorEmail = (roomId: string) => {
    console.warn("Config Defaulted to version 100")
    if (!defaultConfigErrorRoomIDArray.includes(roomId)) {
      setDefaultConfigErrorRoomIDArray((curr) => {
        return [...curr, roomId]
      })
      profile &&
        initialConfig &&
        initialConfig.serialNumber &&
        sendConfigVersion100ProbableErrorEmail(
          profile,
          initialConfig.serialNumber,
          initialConfig.configHex,
          roomId
        ).then((res: string) => {
          profile.role === "Admin" && console.warn(res)
        })
    }
  }

  const clearCurrentSession = () => {
    setRoomId(undefined)
    setClient(undefined)
    setQuestionnaire(undefined)
  }

  const clearCurrentHand = () => {
    setInitialConfig(undefined)
    setCurrentConfig(undefined)
    setConfigError(undefined)
    setElecA(0)
    setElecB(0)
    setHandStatus(undefined)
    setDigitPositions(undefined)
    setErrorLogs(undefined)
    clearTimeout(errorChecker)
    setCommandResponse(undefined)
  }

  const convertRasConfigToConfig = (rasConfig: RASConfig): Config => {
    return {
      name: rasConfig.name,
      date: rasMakeDate(rasConfig),
      setBy: rasConfig.setBy,
      configHex: rasConfig.config,
      configVersion: rasConfig.configVersion,
      firmwareVersion: rasConfig.firmwareVersion,
      isLive: rasConfig.isLive,
      gatewareVersion: rasConfig.gatewareVersion,
      userAppVersion: rasConfig.userAppVersion,
      serialNumber: rasConfig.serialNumber,
      parsed: rasConfig.parsed,
      userGrips: rasConfig.userGrips as [string, string, string, string, string, string],
      archivedBy: rasConfig.archivedBy,
      configImportedFrom: rasConfig.configImportedFrom,
    }
  }

  const setSession = (data: SessionData) => {
    setQuestionnaire(data.questionnaire)
    data.initialConfig && setInitialConfig(convertRasConfigToConfig(data.initialConfig))
    data.currentConfig && setCurrentConfig(convertRasConfigToConfig(data.currentConfig))
    setErrorLogs((current) => {
      return {
        latestTechTeamErrorTime: current?.latestTechTeamErrorTime || 0,
        errorCount: current?.errorCount || 0,
        errorArray: data.errorLog || [],
      }
    })
  }

  const disconnected = (error: string) => {
    clearCurrentSession()
    clearCurrentHand()
    setContextError(error)
  }

  const connectToServer = () => {
    socket?.emit(
      "connectTechnician",
      {
        name: `${profile!.first_name} ${profile!.last_name}`,
        email: user?.email || "No email given",
        token: authToken,
      },
      (response: { connected: boolean }) =>
        !response.connected && setContextError("Connection error.")
    )
  }

  const dataFromClient = (content: DataEmission) => {
    switch (content.type) {
      case "config":
        if (typeof content.data === "string") {
          setCurrentConfig(
            (current) =>
              ({
                ...current,
                ...rasConfig,
                configHex: rasConfig.config,
                date: rasMakeDate(rasConfig),
              } as Config)
          )
        } else if (typeof content.data === "object") {
          const rasConfig = content.data as RASConfig
          return setCurrentConfig(
            (current) =>
              ({
                ...current,
                ...rasConfig,
                configHex: rasConfig.config,
                date: rasMakeDate(rasConfig),
              } as Config)
          )
        }
        break
      case "finished_config":
        const rasConfig = content.data as RASConfig
        return setCurrentConfig(
          (current) =>
            ({
              ...current,
              ...rasConfig,
              configHex: rasConfig.config,
              date: rasMakeDate(rasConfig),
            } as Config)
        )
      case "electrode":
        const [a, b] = content.data as [number, number]
        setElecA(a)
        setElecB(b)
        break
      case "status":
        if ((content.data as HandStatus).currentGrip === "No Grip") {
          return
        } else {
          return setHandStatus(content.data as HandStatus)
        }
      case "digits":
        return setDigitPositions(content.data as DigitPositions)
      case "disconnected hand":
        return clearCurrentHand()
      case "error_data":
        clearTimeout(errorChecker)
        const { errorCount, errorArray } = content.data as ErrorCodeResponse
        currentErrorsLength.current = errorArray.length
        errorChecker = setTimeout(
          () =>
            !((errorCount > 511 ? 511 : errorCount) === errorArray.length) &&
            emitData({ type: "request_errors", data: "Error Log" }),
          10000
        )
        return setErrorLogs(content.data as ErrorCodeResponse)
      case "command response":
        if (isInCustomBLECommand || isInConfigActions) {
          setCommandResponse(content.data as string)
        }
        return
      case "current_view":
        return viewSetter(content.data as string)
      case "electrode update":
        return setElectrodeUpdate(content.data as string)
      case "config error":
        return setConfigError(content.data as string)
      default:
        profile?.role === "Admin" && console.log("DATA OF UNKNOWN TYPE", content)
        break
    }
  }

  const updateConnection = ({
    message,
    serverRooms,
    currentConnections,
    technicians,
  }: UpdateConnection) => {
    serverRooms.forEach((room: Room) => room.id === roomId && setClient(room.userName))
    setLiveRooms(serverRooms)
  }

  const joinRoom = (id: string) => {
    socket?.emit("joinRoom", id, (response: SessionData) => {
      if (response.questionnaire) {
        setRoomId(id)
        setSession(response)
        viewSetter(response.currentView)
        liveRooms?.filter((room) => room.id === id && setClient(room.userName))
        navigate(`/remote-assist/${id}`)
      } else {
        setContextError(`Couldn't join; ${response}`)
      }
    })
  }

  const leaveRoom = () => {
    if (!roomId) return
    clearCurrentHand()
    clearCurrentSession()
    socket?.emit("leaveRoom", roomId, ({ leftRoom }: { leftRoom: boolean }) => {
      !leftRoom && setContextError("Could not leave room correctly")
    })
  }

  const closeRoom = (id: string) => {
    socket?.emit(
      "closeRoom",
      id,
      ({ message }: { message?: string }) => message && setContextError(message)
    )
  }

  const getArchived = () => {
    socket?.emit("getArchived", (data: Room[] | { error: string }) =>
      Array.isArray(data) ? setArchivedRooms(data) : setContextError(data.error)
    )
  }
  const getArchivedRoom = (id: string) => {
    socket?.emit("getRoomData", id, (data: SessionData | string) => {
      if (typeof data === "string") {
        setContextError(data)
      } else {
        setSession(data)
        setRoomId(id)
        navigate(`/remote-assist/${id}`)
      }
    })
  }

  const emitData = ({ type, data }: DataEmission) => {
    socket?.emit("data", { type, data, room: roomId })
  }

  const clearCurrentCommandResponse = () => {
    setCommandResponse(undefined)
  }

  const viewSetter = (view: string) => {
    clearTimeout(errorChecker)
    switch (view) {
      case "config":
        return setCurrentView("Config")
      case "digits":
        return setCurrentView("Digit Data")
      case "error log":
        return setCurrentView("Errors")
      case "status":
        return setCurrentView("Hand Status Data")
      case "electrode":
        return setCurrentView("Electrode Data")
      default:
        return
    }
  }

  return (
    <RemoteAssistContext.Provider
      value={{
        contextError,
        configError,
        setConfigError,
        currentView,
        setIsInCustomBLECommand,
        setIsInConfigActions,
        roomId,
        questionnaire,
        client,
        initialConfig,
        currentConfig,
        elecA,
        elecB,
        electrodeUpdate,
        handStatus,
        digitPositions,
        errorLogs,
        commandResponse,
        liveRooms,
        archivedRooms,
        joinRoom,
        closeRoom,
        getArchived,
        getArchivedRoom,
        emitData,
        clearCurrentCommandResponse,
        defaultConfigErrorRoomIDArray,
        sendDefaultConfigErrorEmail,
      }}
    >
      {children}
    </RemoteAssistContext.Provider>
  )
}

export { RemoteAssistProvider, RemoteAssistContext }
