import { ProfileData } from "@covvi/common-functions"
import { ErrorAndTime, ErrorCodeData, GeneralErrorsObj } from "@typesFolder/types"
import { collection, getDocs, query, where } from "firebase/firestore"
import { getBlob, listAll, ref, StorageReference, uploadBytes } from "firebase/storage"
import { firestore, storage } from "../firebase"
import { updateHandField } from "./handFunctions"

export const getLatestErrors = ({
  handId,
  uid,
  role,
  associatedUsers,
}: {
  handId: string
  uid: string
  role: string
  associatedUsers?: string[]
}) => {
  return new Promise<ErrorCodeData[]>((resolve, reject) => {
    const getAll = ["Admin", "Tech Team Member", "Customer Service Team Member"].includes(role)
    const docRef = getAll
      ? collection(firestore, `Hands/${handId}/HandErrors`)
      : query(
          collection(firestore, `Hands/${handId}/HandErrors`),
          where("set_by", "in", associatedUsers ? [...associatedUsers.slice(0, 28), uid] : [uid])
        )
    getDocs(docRef)
      .then((querySnapshot) => {
        let errorMap = new Map()
        if (getAll) {
          querySnapshot.forEach((errorSnap) => {
            let snapshotErrors = Object.values(errorSnap.data()) as ErrorCodeData[]
            snapshotErrors.pop()
            snapshotErrors.forEach((error) =>
              errorMap.set(`AT${error.absTime}T${error.type}C${error.code}`, error)
            )
          })
          resolve(Array.from(errorMap.values()))
        } else if (querySnapshot.docs.length) {
          let snapshotError = Object.values(
            querySnapshot.docs[querySnapshot.docs.length - 1].data() as ErrorCodeData[]
          )
          snapshotError.pop()
          resolve(snapshotError)
        }
      })
      .catch((e) => {
        console.error("failed to get errors", e)
        reject(e)
      })
  })
}

export const getGeneralErrors = (profile: ProfileData) => {
  return new Promise<GeneralErrorsObj[]>(async (resolve, reject) => {
    let allHandsArray: GeneralErrorsObj[] | void = []
    let latest: StorageReference | undefined
    let hands: string[] = []

    const getInt = (ref: StorageReference) => parseInt(ref.name.replace(".json", ""))

    const getHandErrors = (handId: string) => {
      return new Promise<GeneralErrorsObj>((resolve) => {
        const blankResolve = {
          handId,
          handErrors: [],
          entries: 0,
        }

        getDocs(collection(firestore, `Hands/${handId}/HandErrors`))
          .then((handSnapshot) => {
            const handErrors: ErrorAndTime[] = []
            handSnapshot.docs.forEach((snapshot, i) => {
              const errors = Object.values(snapshot.data()) as ErrorAndTime[]
              errors.pop()
              errors.forEach((er) => {
                const existsIndex = handErrors.findIndex(
                  (obj) =>
                    obj.absTime === er.absTime && obj.type === er.type && obj.code === er.code
                )
                if (existsIndex === -1) {
                  handErrors.push({ ...er, earliestDate: parseInt(snapshot.id) })
                } else if (parseInt(snapshot.id) < handErrors[existsIndex].earliestDate) {
                  handErrors[existsIndex].earliestDate = parseInt(snapshot.id)
                }
              })
            })
            handErrors.sort((a, b) => a.earliestDate - b.earliestDate)
            resolve({
              handId,
              handErrors,
              entries: handErrors.length,
            })
          })
          .catch(() => resolve(blankResolve))
      })
    }

    await listAll(ref(storage, "CachedErrorsSnapshots/")).then((result) =>
      result.items.forEach((ref) => {
        if (!latest || getInt(ref) > getInt(latest)) {
          latest = ref
        }
      })
    )

    if (!latest || (latest && getInt(latest) + 604800000 < Date.now())) {
      await getDocs(query(collection(firestore, "Hands"))).then(
        async (res) =>
          await Promise.all(
            res.docs
              .map((hand) => hand.id)
              .map((hand) => updateHandField(hand, "HandErrors", "latest_errors"))
          )
      )
      await getDocs(query(collection(firestore, `Hands`), where("latest_errors", "!=", null)))
        .then((querySnapshot) => querySnapshot.forEach((doc) => hands.push(doc.id)))
        .catch((e) => reject(`Couldn't get Errors: ${e}`))
      allHandsArray = await Promise.all(hands.map((hand) => getHandErrors(hand)))
        .then((res) => res.filter((hand) => hand.entries > 0))
        .catch((e) => reject(`Couldn't get Stats: ${e}`))
      if (allHandsArray) {
        resolve(allHandsArray)
        uploadBytes(
          ref(storage, `CachedErrorsSnapshots/${Date.now()}.json`),
          new Blob(
            [
              JSON.stringify({
                set_by: profile.uid,
                hands_array: allHandsArray,
                date: new Date().toISOString().replace("T", " ").split(".")[0],
              }),
            ],
            { type: "application/json" }
          )
        )
      } else {
        reject(`Couldn't retrieve Errors for hands: ${hands}`)
      }
    } else {
      await getBlob(latest)
        .then(async (blob) => await blob.text())
        .then(async (json) => await JSON.parse(json))
        .then((errorSnap) => resolve(errorSnap.hands_array))
        .catch(() => reject("Error retrieving latest cached stats"))
    }
  })
}
