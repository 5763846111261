import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { languageSelector as ls } from "@covvi/language-selector"

const WhatIsRemoteAssist = () => {
  return (
    <div className="w-full mb-[8px] lg:mb-[15px]">
      <Accordion
        sx={{
          backgroundColor: "#E4E4E4",
          boxShadow: "none",
          "&.MuiPaper-root": {
            borderRadius: "15px",
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography sx={{ color: "#525252" }}>{ls.getText("What is Remote Assist?")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ color: "#525252" }}>{ls.getText("remote_assist_blurb")}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default WhatIsRemoteAssist
