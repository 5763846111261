export const configHex105 =
  "434647050B03006B63D146C8010100345244315100424C574817000800420000000200640100000000000000000000000E0B012C02ED03E800FA069000960032138864000000000006CA012C027003E800FA057800960032138864000000000003E801F403E803E803E803E803E80000000000000001030301F407D00101010104000B0C0000010E0000000D0B0000000001003F0000000000000000000000002CD200002CD200002CD200002CD200002CC8000046E60000879095B4E60000000000000000000000029E96D064646464040108021100000001030000000000000103000000000000000000000000000000000000000000000000000000000000"

export const configHex107 =
  "434647060B0E006B6544C332020104CB5244315100424C5748140008004200000002006400000000000000000000000010EB012C02A900FA01AF0A580096003213F36400000000000F68012C057D048300FA057D00960032138864000000000003E801F403E803E803E803E803E80000000000000001030303E807D00101010104050B0C000000000000000D0C0000000000003F0000000000000000000000002CD200002CD200002CD200002CD200002CC8000046D70000A08C96B4D2000000000000000000000002AA96BE64646464010A00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"

export const configHex106 =
  "434647060408006B63D146C8010100345244315100424C574817000800420000000200640000000000000000000000001194012C04E203E800FA05780096003213246400000000001194012C04E203E800FA057800960032138864000000000003E801F403E804B003E803E803E80000AFC800000001030303E807D00101010104000B0C000000000000000D0B0000000001003F0000000000000000000000002CD200002CD200002CD200002CD200002CC8000046E60000879095B4E60000000000000000000000029E96D06464646403020E0C0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"

export const thumbSwitching =
  "434647060101006B67748580010100004356315100424C57481400000F420000000200640000000000000000000000001194012C04E203E800FA05780096003213886400000000001194012C04E203E800FA057800960032138864000000000003E801F403E803E803E803E803E80000000000000001030303E807D00A01010104000B0C0000000000000E0D0B000000010006000000000000000000000000002EDE000028CF00002BD1000029D300002BCD000046D700008F8F9EA7D50000005872717072000000FEA29FC900000000030102040000000003060709000000000000000000000000000000000000000000000000000000000000000000000000"
