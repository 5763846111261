import { useEffect, useRef, useState } from "react"
import Alert, { AlertColor } from "@mui/material/Alert"

import { languageSelector as ls } from "@covvi/language-selector"
import { useCurrentHandContext, useCurrentSessionContext } from "@context/remoteAssistProviders"
import { useAuth } from "@context/AuthContext"

import HandConfigSection from "../sections/HandConfigSection"
import AdditionalTools from "@components/sections/AdditionalTools"
import QuestionnaireModal from "@components/modals/remoteAssist/QuestionnaireModal"

import BasicModal from "@ui/modals/BasicModal"
import { CurrentGripConnection } from "@components/sections/CurrentGripConnection"
import { PillButtonArray } from "@ui/sections/PillButtonArray"
import ErrorLog from "@components/sections/additionalTools/ErrorLog"
import { ButtonObj } from "@typesFolder/types"
import { AlertSection } from "@ui/alerts/AlertSection"
import { Config } from "@covvi/common-functions"

const RemoteAssistRoom = ({ showingArchived }: { showingArchived: boolean }) => {
  const { profile } = useAuth()
  const isCovvi = [
    "Sales Team Member",
    "Customer Service Team Member",
    "Tech Team Member",
    "Admin",
  ].includes(profile!.role)
  const {
    client,
    currentView,
    roomId,
    defaultConfigErrorRoomIDArray,
    sendDefaultConfigErrorEmail,
  } = useCurrentSessionContext()
  const {
    initialConfig,
    currentConfig,
    configError,
    setConfigError,
    handStatus,
    errorLogs,
    emitData,
  } = useCurrentHandContext()
  const [selected, setSelected] = useState(showingArchived ? "Initial Configuration" : "Config")
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState<undefined | AlertColor>()
  const [newHex, setNewHex] = useState<undefined | string>()
  const [newConfig, setNewConfig] = useState<Config>()
  const [showingQuestionnaire, setShowingQuestionnaire] = useState(false)
  const updateTimer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (
      profile &&
      initialConfig &&
      initialConfig.configVersion?.toString() === "100" &&
      initialConfig.serialNumber &&
      roomId &&
      !defaultConfigErrorRoomIDArray.includes(roomId) &&
      !showingArchived
    ) {
      sendDefaultConfigErrorEmail(roomId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfig, profile, roomId, showingArchived])

  useEffect(() => {
    updateTimer.current && clearTimeout(updateTimer.current)
    if (newHex) {
      setLoading(false)
      setNewHex(undefined)
    }

    if (currentConfig && !showingArchived) {
      setNewConfig(currentConfig)
    } else if (initialConfig) {
      setNewConfig(initialConfig)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfig, currentConfig])

  useEffect(() => {
    if (newHex) {
      setNewConfig((current) => current && { ...current, configHex: newHex })
    }
  }, [newHex])

  useEffect(() => {
    if (!showingArchived) {
      currentView === "Config" ? setSelected("Config") : setSelected("Additional Tools")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView])

  const buttonArray: ButtonObj[] = [
    {
      title: ls.getText("Apply Changes to Hand"),
      loading: loading,
      variant: "contained",
      disabled: !newHex || (!isCovvi && !!roomId && defaultConfigErrorRoomIDArray.includes(roomId)),
      onClick: () => {
        if (newHex) {
          updateTimer.current = setTimeout(() => setNewConfig(newConfig), 5000)
          emitData({ type: "config", data: newHex })
          setLoading(true)
        }
      },
    },
  ]

  const archived = [
    {
      title: "Initial Configuration",
      onClick: () => setSelected("Initial Configuration"),
      content: <HandConfigSection config={initialConfig} canEdit={false} />,
    },
    {
      title: "Updated Configuration",
      onClick: () => setSelected("Updated Configuration"),
      content: <HandConfigSection config={currentConfig} canEdit={false} />,
    },
    {
      title: "Error Logs",
      onClick: () => setSelected("Error Logs"),
      content: (
        <ErrorLog
          errorDataArray={errorLogs?.errorArray || []}
          latestTechError={errorLogs?.latestTechTeamErrorTime || 0}
        />
      ),
    },
  ]

  const live = [
    {
      title: "Config",
      onClick: () => {
        if (loading) return
        setSelected("Config")
        emitData({ type: "current_view", data: "config" })
      },
      content: (
        <HandConfigSection
          config={newConfig}
          canEdit={client ? true : false}
          setCurrentConfig={setNewHex}
          isLiveRemoteAssist={true}
          buttonArray={buttonArray}
          isLoading={loading}
        />
      ),
    },
    {
      title: "Additional Tools",
      onClick: () => !loading && setSelected("Additional Tools"),
      content: (
        <AdditionalTools
          disableElectrodeHandles={
            !isCovvi && !!roomId && defaultConfigErrorRoomIDArray.includes(roomId)
          }
        />
      ),
    },
  ]

  return (
    <div>
      <div className="flex flex-col sm:flex-row">
        <PillButtonArray
          pillArray={showingArchived ? archived : live}
          selected={selected}
          setSelected={() => setSelected}
          extraMarginBottom={true}
        />
        {!showingArchived && (
          <CurrentGripConnection config={initialConfig} handStatus={handStatus} />
        )}
      </div>
      {roomId && defaultConfigErrorRoomIDArray.includes(roomId) && (
        <AlertSection
          severity="error"
          title={ls.getText("Config Error")}
          content="config_error_text"
        />
      )}
      {[...archived, ...live].filter((object) => object.title === selected)[0].content}

      <BasicModal open={showingQuestionnaire} setClosed={() => {}} fullWidth={true}>
        <QuestionnaireModal setShowingQuestionnaire={setShowingQuestionnaire} canEdit={true} />
      </BasicModal>
      {alert && (
        <BasicModal open={alert ? true : false} setClosed={() => setAlert(undefined)}>
          <Alert severity={alert} onClose={() => setAlert(undefined)}>
            {ls.getText(alert === "error" ? "error_setting_config" : "new_config_saved")}
          </Alert>
        </BasicModal>
      )}
      {configError && (
        <BasicModal open={configError ? true : false} setClosed={() => setConfigError(undefined)}>
          <Alert severity={"error"} onClose={() => setConfigError(undefined)}>
            {ls.getText("alerts_config_validation_failed_title")}
          </Alert>
        </BasicModal>
      )}
    </div>
  )
}
export default RemoteAssistRoom
