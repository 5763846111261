import { TextField, MenuItem } from "@mui/material"
import { languageSelector as ls } from "@covvi/language-selector"
import HelpTextTooltip from "@ui/buttons/HelpTextTooltip"

type OptionType = { value: string; displayValue: string; disabled?: true }

const SelectInput = ({
  title,
  error,
  currentValue,
  options,
  setValue,
  canEdit = true,
  helperText,
}: {
  title: string
  error?: string
  currentValue: string
  options: OptionType[]
  setValue: (paramName: string, value: string) => void
  canEdit?: boolean
  helperText?: string
}) => {
  return (
    <div className="flex justify-between py-2 items-center">
      <TextField
        className="basis-full"
        error={
          currentValue === ls.getText("na_key") || currentValue === undefined || error
            ? true
            : false
        }
        label={ls.getText(title)}
        variant="outlined"
        value={currentValue}
        select
        onChange={(e) => setValue(title, e.target.value)}
        required
        disabled={!canEdit}
        size="small"
      >
        {options
          .sort((a: OptionType, b: OptionType) => a.displayValue.localeCompare(b.displayValue))
          .map(({ value, displayValue, disabled }, i: number) => (
            <MenuItem key={i} value={value} disabled={disabled}>
              {ls.getText(displayValue)}
            </MenuItem>
          ))}
      </TextField>
      <HelpTextTooltip text={helperText || "NO-HELPTEXT"} />
    </div>
  )
}

export default SelectInput
